  .slider-wrapper-google-reviews {
    background-color: transparent;
    user-select: none;
    cursor: pointer;
    padding-top: 1rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 1rem;
    border-radius: 5px; 
  }
 
  .slider-wrapper-google-reviews .clients_info h1{
    color: #E9D06B;
    font-weight: 700; 
    text-transform: uppercase;
  }

  .slider-wrapper-google-reviews .picture {
    width: 65px;
    height: 65px;
  }
   
  .slider-wrapper-google-reviews .clients_info p {
    color: #ffffff;
    font-weight: 500;
    font-size: 0.9rem;
  }
  
  .slider-wrapper-google-reviews .head-title {
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 0.64rem;
    line-height: 1;
  }

  .slider-wrapper-google-reviews .title { 
    margin-top: 0;
    color: #ffffff;
    margin-bottom: 0;
    line-height: 1;
  }

  .slider-wrapper-google-reviews .swiper-pagination-bullet-active{
    background-color: #ffffff;
  }

  .slider-wrapper-google-reviews  .container {
    max-width: 960px;
    margin: 0 auto; 
    padding: 20px;
  }
 
  .slider-wrapper-google-reviews .card .review-time-lapse {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider-wrapper-google-reviews .card .review-time-lapse img{
    margin-right: 10px;
  }

  .slider-wrapper-google-reviews  .card {
    background-color: transparent; 
    padding: 10px;
    height: 327px;
    transition: all 0.2s ease-in-out;
    overflow-y: auto;
    border-radius: 4px;
    border: 2px solid white; 
  }
 
  .slider-wrapper-google-reviews .swiper-pagination-bullet {
    background-color: #E9D06B ;
  }

  .card.hovered { 
    transition: all 0.2s ease-in-out;
  }

  .slider-wrapper-google-reviews .card::-webkit-scrollbar {
    height: 1px;
    width: 1px;
    background-color: #E9D06B;
  }
  
  .slider-wrapper-google-reviews .card::-webkit-scrollbar-thumb {
    background-color: #E9D06B;
    border-radius: 10px;
  }
  
  .slider-wrapper-google-reviews .card::-webkit-scrollbar-thumb:hover {
    background-color: #E9D06B;
  }
  
  .slider-wrapper-google-reviews .card::-webkit-scrollbar-track {
    background-color: #ffffff;
  }

  .slider-wrapper-google-reviews .card p {
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: 500;
  }
  
  .slider-wrapper-google-reviews .card .client-icon {
    width: 117px;
    height: 117px; 
  }

  .slider-wrapper-google-reviews .card img {
    margin-bottom: 10px;
    width: 85px;
  }
  
  .slider-wrapper-google-reviews .card h1 {
    font-size: 1.3rem;
    margin-bottom: 10px; 
    font-weight: 700;
  }

  
@media screen and (max-width: 1199px) { 
  .slider-wrapper-google-reviews .clients_info p, .slider-wrapper-google-reviews .card p  {
    font-size: 1rem;
  }
}

@media screen and (max-width: 991px) { 
  .slider-wrapper-google-reviews .clients_info p, .slider-wrapper-google-reviews .card p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 767px) { 
  .slider-wrapper-google-reviews .clients_info p, .slider-wrapper-google-reviews .card p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 500px) {
  .slider-wrapper-google-reviews .card h1 {
    font-size: 1.2rem;
  } 
  .slider-wrapper-google-reviews .clients_info p, .slider-wrapper-google-reviews .card p {
    font-size: 0.8rem;
  } 
}

@media screen and (max-width: 450px) {
  .slider-wrapper-google-reviews .card h1 {
    font-size: 1rem;
  } 
  .slider-wrapper-google-reviews .clients_info p, .slider-wrapper-google-reviews .card p {
    font-size: 0.7rem;
  }
}
 
