.associations_swiper_slider { 
    position: relative; 
    width: 80%;
    z-index: 1;
    padding-bottom: 2.7rem;
    padding-top: 1.5rem; 
    margin: 0 auto;
    border: 1px solid white;
    border-radius: 25px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 8px 52px -6px rgba(0, 0, 0, 0.2);
}

.associations_swiper_slider h2 {
    color: #000000;
    font-weight: 700;
}

.associations_swiper_slider .container {
    max-width: 1200px;  
    margin: 0 auto;
}

.associations_swiper_slider .mySwiper .normal_w {
    width: 100%;
    height: 58px;
}

.associations_swiper_slider .mySwiper .bnc_logo { 
    width: 75px;
    height: 58px;
}

.associations_swiper_slider .mySwiper .opportunity_logo { 
    width: 105px;
    height: 58px;
}

.associations_swiper_slider  .mySwiper {
    position: relative;
    z-index: 1;
    user-select: none;
    cursor: pointer;
}
  
.associations_swiper_slider .swiper-slide {
    text-align: center;
}
  
@media only screen and (min-width: 640px) {
    .associations_swiper_slider .swiper-slide {
      width: 50%;
    }
}
  
@media only screen and (min-width: 768px) {
    .associations_swiper_slider .swiper-slide {
      width: 50%;
    }
    .associations_swiper_slider .swiper-slide img {
        max-width: 100%;
        max-height: 100%;
    }
}

@media only screen and (min-width: 1024px) {
    .associations_swiper_slider .swiper-slide {
      width: 25%;
    }
    .associations_swiper_slider .swiper-slide img {
        max-width: 100%;
        max-height: 100%;
    }
}
