.choose_events {
    background-color: #F6F5F8;
    padding-top: 2rem;
    user-select: none;
    padding-bottom: 9rem;
}

.choose_events .events_info h1 {
    color: #000000;
    font-weight: 700;
    font-size: 2.2rem;
}

.choose_events .events_info p {
    color: #5A5A5A;
    font-weight: 500;
    font-size: 1.2rem;
}

.choose_events .container-fluid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .choose_events .box {
    width: 269px;
    transition: 0.2s ease-in-out;
    height: auto;
    line-height: 1;
    margin: 10px;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(109, 109, 109, 0.13);
    border-radius: 6px;
  }

  .choose_events .box:hover {
    transition: 0.2s ease-in-out;
    transform: scale(1.05);
  }

  .choose_events .box .box_info h1 {
    color: #000000;
    font-weight: 700;
    font-size: 1.5rem; 
  }
  
  .choose_events .box .box_info p {
    color: #5A5A5A;
    font-weight: 500; 
    font-size: 1rem;
  }

  @media screen and (max-width: 460px) { 
    .choose_events .events_info p {
        font-size: 0.9rem;
    }
    .choose_events .events_info h1 {
        font-size: 2rem;
    }
  }
  
  @media screen and (max-width: 360px) { 
    .choose_events .events_info p {
        font-size: 0.8rem;
    }
    .choose_events .events_info h1 {
        font-size: 1.7rem;
    }
  }