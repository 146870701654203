.schedule_programs_wrapper {
    margin-bottom: 2rem;
}

.schedule_programs_wrapper .schedule_heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2rem;
}

.schedule_programs_wrapper .schedule_heading h1{
    color: #000000;
    font-weight: 700; 
}