html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
  
.quest_sp_about_page .quest_sp_banner_regular_component{
  height: 800px; 
}

.quest_sp_about_page .quest_sp_banner_regular_component::before{ 
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:  800px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
} 

.quest_sp_about_page .quest_sp_banner_regular_component .content-wrapper {
  z-index: 99;
}

.user_success_checkout .success_message {
  height: 6vh;
  background-color: #D4EDDA;
  border-radius: 0px; 
  color: #000000;
  font-weight: 500;
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  align-items: center;
  flex-direction: column;
}

.user_success_checkout .fail_message {
  height: 6vh;
  background-color: #F8D7DA;
  border-radius: 0px; 
  color: #000000;
  font-weight: 500;
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  align-items: center;
  flex-direction: column;
}

.navigating_message { 
  border-radius: 0px; 
  color: #000000;
  font-weight: 500;
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  align-items: center;
  flex-direction: column;
}
 
.skew-btn {
  background-color: #000000; 
  border: none;
  color: #E9D06B;
  padding: 7px 43px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px; 
  cursor: pointer;
  font-weight: 700; 
  text-transform: uppercase;
  transform: skewX(-15deg);
  transition: 0.3s;
}

.drp_slct {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  font-style: italic;
}

.skew-btn:hover {
  background-color: #E9D06B; 
  color: #000000;
  transition: 0.3s;
}
 
.line {
  width: 100%;
  height: 1px;
  background-color: #AFAFAF;
}

.skew-btn:focus { 
  outline: none; 
}

h1 {
    font-size: 3rem;
    user-select: none;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.8rem;
}

p {
  font-size: 1.2rem;
  user-select: none;
  color: #5A5A5A;
}

  .asterik_sign {
      color: #C52439;
  }

  .loader {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader-icon {
    width: 180px;
    height: 180px;
  }
  
  .spinner {
    border: 4px solid #E9D06B ;
    border-top-color: #ffffff;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    animation: spinner-rotate 1s linear infinite;
    position: absolute;
  }

  /* Alert Function CSS */
  .alert {
    padding: 0.5em;
    margin: 1em;
    border-radius: 5px;
    font-weight: bold;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
  }
  
  .alert.success {
    background-color: #16a036;
    color: #ffffff; 
    font-weight: 500;
  }
  
  .alert.failure {
    background-color: #c02c38;
    color: #ffffff; 
    font-weight: 500;
  }

  /* Animations - */
 
  .swoosh-effect {
    position: relative;
    display: inline-flex;
    color: #E9D06B;
  }
  
  .swoosh-effect::before {
    content: '';
    position: absolute;
    bottom: -3px;
    width: 50%;
    height: 5px;
    background-color: #E9D06B;
    transform-origin: right bottom;
    transform: skewX(190deg);
    animation: swoosh-animation 1s ease-in-out infinite;
  }
  
  @keyframes swoosh-animation {
    0% {
      transform: skewX(-20deg) translateX(0);
    }
    50% {
      transform: skewX(-20deg) translateX(30px);
    }
    100% {
      transform: skewX(-20deg) translateX(0);
    }
  }
 
  /* left to right */

  .slide-in {
    animation-name: slide-in;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }
  
  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
 
  /* right to left */

.slide-out {
  animation-name: slide-out;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes slide-out {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(-0%);
  }
}


  /* top to bottom */

  .slide-down {
    animation: slide-down 1s ease;
  }
  
  @keyframes slide-down {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  /* -- */
  
  @keyframes spinner-rotate {
    to {
      transform: rotate(360deg);
    }
}

@media screen and (max-width: 1199px) {
    h1 {
        font-size: 2.5rem;
    }

    p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 991px) {
    h1 {
        font-size: 2.2rem;
    }

    p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 767px) {
    h1 {
        font-size: 1.7rem;
    }

    p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 500px) {
    h1 {
        font-size: 1.4rem;
    }

    p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 450px) { 
  h1 {
    font-size: 1.4rem;
}
  p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 380px) {
  p {
    font-size: 0.9rem;
  }
}
 
@media screen and (max-width: 1199px) {
    .skew-btn {
      font-size: 12px;
      padding: 6px 40px;
    }
}

@media screen and (max-width: 991px) {
    .skew-btn {
      font-size: 12px;
      padding: 5px 35px;
    }
}

@media screen and (max-width: 767px) {
  .skew-btn {
    font-size: 12px;
    padding: 4px 30px;
  }
}

@media screen and (max-width: 400px) {
  .skew-btn {
    font-size: 11px;
    padding: 4px 25px;
  }
}
 
  @media (max-width: 1024px) {
    h2 {
      font-size: 1.8rem;
    }
  }
  
  @media (max-width: 768px) {
    h2 {
      font-size: 1.6rem;
    }
  }
  
  @media (max-width: 540px) {
    h2 {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 360px) {
    h2 {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 280px) {
    h2 {
      font-size: 1rem;
    }
  }