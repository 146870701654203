.footer_component {
  padding-top: 5rem;
  background-color: #000000; 
  user-select: none;
  font-family: 'Montserrat', sans-serif;
}
 

.footer_component .bottom-column-info .ca_logo img{
  height: 25px;
}

.footer_component  .location span a {
  color: #E9D06B !important;  
  text-decoration: none; 
}

.footer_component  .location span a:hover {
  color: #E9D06B !important; 
  text-decoration: none; 
}

.footer_component .company-logo {
  margin-bottom: 2rem; 
  width: auto;
  height: 70px;
}

.footer_component .footer-links ul{
  text-decoration: none;
  list-style: none;
}
 
.footer_component .info-icon {
  height: 17px;
  margin-right: 10px;
}

.footer_component .company-info ul li{
  font-size: 0.9rem;
  text-transform: capitalize;
  line-height: 3; 
}

.footer_component .dropdown-menu li:last-child {
  border-bottom: none;
}

.footer_component  ul li {
    text-transform: uppercase;
    font-weight: 500;
    color: #E9D06B;
    cursor: pointer;
}
 
.footer_component .location span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    font-size: 0.9rem;
    color: #E9D06B;
    font-weight: 400;
  }
  
  .footer_component .location br {
    display: none;
  }
   
.footer_component ul li {
    line-height: 3;
    list-style-type: none;
}

.footer_component ul li a {
    text-decoration: none;
    color: #E9D06B;
}

.footer_component ul li span .expand-icon{
    height: 15px;
    margin-left: 6px;
    margin-bottom: 5px;
}

.footer_component .certified-logo {
    height: 125px; 
}

  .footer_component .input-group {
    position: relative;
    display: flex;
    align-items: center;
  }

  .footer_component .search-box { 
    width: auto; 
    display: flex;
    width: 400px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 0;
    height: 60px;
  }
   
  .footer_component .search-box input {
    border: none;
    outline: none;
    padding-left: 10px; 
    flex: 1;
  }
  
  .footer_component .search-box button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: 10px;
  }

  .footer_component .certified-logo {
    padding-left: 3.7rem;
  }

  .footer_component .search-box button img {
    height: 60px;
  }
  
  .footer_component .search-box button:focus {
    outline: none;
  }
    
  .footer_component .subscription_social_links a{
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    height: 44.2px;
  }

  .footer_component .subscription_social_links a:hover img {
    transform: scale(1.2);
  }

  .footer_component .subscription_social_links a img {
    transition: transform 0.3s ease-in-out;
  }

  .footer_component .subscription_social_links a img {
    height: 44.2px;
  }

  .footer_component .subscription_social_links a:focus {
    outline: none;
  }
     
.footer_component .bottom-column-info p {
    font-size: 0.8rem;
    color: #fff;
    font-weight: 500;
}

.footer_component .bottom-column-info .cs {
    font-weight: 800;
}

.footer_component .payment-icons {
  height: 25px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 1200px){ 
  .footer_component .certified-logo {
    padding-left: 2.4rem;
  } 
}

@media screen and (max-width: 991px){
  .footer_component .search-box {
    margin: 0 auto;
  }
 
  .footer_component .certified-logo {
    padding-left: 0;
  }
}

@media screen and (max-width: 560px){
  .footer_component .company-logo {
    width: 100%;
    height: 64px;
  } 
}


@media screen and (max-width: 440px){
  .footer_component .search-box {
    width: auto;
  }
}

@media screen and (max-width: 420px) {
  .footer_component .company-logo {
    width: 100%;
    height: 55px;
  } 
}

@media screen and (max-width: 410px) {
  .footer_component .search-box {
    height: 50px;
  }
  .footer_component .search-box button img {
    height: 50px;
  }

  .footer_component .subscription_social_links button{ 
    height: 34.2px;
  }

  .footer_component .subscription_social_links button img {
    height: 34.2px;
  }
}

@media screen and (max-width: 388px) {
  .footer_component .search-box {
    height: 45px;
  }
  .footer_component .company-logo {
    width: 88%;
    height: 46px;
  } 
  .footer_component .search-box button img {
    height: 45px;
  }
}

.social_links .search-box button {
  transition: all 0.2s ease-in-out;
}
.social_links .search-box button:hover {
  transition: all 0.2s ease-in-out;
}


@media screen and (max-width: 376px) {
  .footer_component .search-box {
    height: 40px;
  }

  .footer_component .search-box button img {
    height: 40px;
  }

  .footer_component .company-logo {
    width: 88%;
    height: 46px;
  } 

  .footer_component .certified-logo {
    width: 100%;
    height: 100px;
  }

  .footer_component .subscription_social_links button{ 
    height: 32.2px;
  }

  .footer_component .subscription_social_links button img {
    height: 32.2px;
  }
}

@media screen and (max-width: 361px) {
  .footer_component .search-box {
    height: 37px;
  }

  .footer_component .search-box  input::placeholder {
    font-size: 0.8rem;
  }

  .footer_component .search-box button img {
    height: 37px;
  }
}

@media screen and (max-width: 353px) {
  .footer_component .search-box {
    height: 34px;
  }

  .footer_component .search-box input {
    width: 100px;
  }
  
  .footer_component .search-box button img {
    height: 34px;
  }
}

@media screen and (max-width: 344px) {
  .footer_component .search-box {
    height: 30px;
  }
  
  .footer_component .search-box button img {
    height: 30px;
  }
}

@media screen and (max-width: 334px) {
  .footer_component .search-box {
    height: 27px;
  }
  
  .footer_component .search-box button img {
    height: 27px;
  }

  .footer_component .subscription_social_links button{ 
    height: 27.2px;
  }

  .footer_component .subscription_social_links button img {
    height: 27.2px;
  }
}