 
.donation-payment-gateway-wrapper .donation-input-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px;
}

.donation-payment-gateway-wrapper .donation-input-wrapper label {
    font-size: 16px;
    margin-bottom: 8px;
}

.donation-payment-gateway-wrapper .donation-input-wrapper input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 16px; 
    outline: none;
}

.donation-payment-gateway-wrapper .donation-input-wrapper button {
    padding: 12px 20px;
    background-color: #E2533C;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    transition: background-color 0.3s;
}

.donation-payment-gateway-wrapper .donation-input-wrapper button:hover {
    background-color: #0056b3;
}

.donation-payment-gateway-wrapper .donation-input-wrapper input[type="number"]::-webkit-inner-spin-button,
.donation-payment-gateway-wrapper .donation-input-wrapper input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.donation-payment-gateway-wrapper .donation-input-wrapper input[type="number"] {
  -moz-appearance: textfield;
}