.elementary_membership {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.elementary_membership .signup_text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
}

.elementary_membership .signup_text h1 { font-weight: 700; }

.elementary_membership  .elementary_membership_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}
  
.elementary_membership .box {
    width: 25%;  
    border: none;
    padding: 35px 11px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column; 
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 0px 25px -8px rgba(0, 0, 0, 0.18);
}

.elementary_membership .swiper-box {
    box-shadow: 0px 0px 25px -8px rgba(0, 0, 0, 0.18);
    padding: 35px 0px; 
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: none;
}

.elementary_membership .membership-swiper-registration .swiper-pagination-bullet {
    background-color: #E9D06B;
}

.elementary_membership .membership-swiper-registration {
    padding-top: 1rem;
    padding-bottom: 5rem; 
}
  
.elementary_membership .icon {
    width: 75px;
    margin-bottom: 1.5rem; 
}
  
.elementary_membership  .heading { 
    margin-bottom: 0.2rem; 
    font-size: 1.1rem;
    font-weight: 700;
}
  
.elementary_membership  .sub-heading {
    color: rgb(159, 159, 159);
    margin-bottom: 1rem; 
    font-size: 1rem;
}
  
.elementary_membership .skew-btn {
    background-color: #E9D06B;
    color: #fff; 
    font-size: 14px;
    padding: 8px 10px;
}
 
.elementary_membership .skew-btn:hover {
    background-color: #E9D06B;
    color: #fff; 
}