.weightlifting_benefits_athletes {
    background-color: #F6F5F8;
    user-select: none;
    padding-top: 5rem;
    padding-bottom: 8rem;
    margin-bottom: 3rem;
}

.weightlifting_benefits_athletes .weightlifting_young_athletes h1 {
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2.2rem;
}

.weightlifting_benefits_athletes .weightlifting_young_athletes p {
    color: #5A5A5A;
    font-size: 0.9rem;
}

.weightlifting_benefits_athletes h4 {
    font-size: 1rem;
    color: #5A5A5A;
    font-weight: 600;
}

.weightlifting_benefits_athletes img{
    width: 19.62px;
    height: 19.62px;
}


@media screen and (max-width: 991px) { 
    /* .weighting_row_wrapper  {
        display: flex;
        align-items: center;
    }
    
    .weighting_row_wrapper > .row {
        width: 40%;
    } */
}

@media screen and (max-width: 768px) {
    .weightlifting_benefits_athletes .weightlifting_young_athletes h1 {
        font-size: 1.7rem; 
    }

    .weightlifting_benefits_athletes .weightlifting_young_athletes p {
        font-size: 0.8rem;
    }  
}

@media screen and (max-width: 433px) {
    .weightlifting_benefits_athletes .weightlifting_young_athletes h1 {
        font-size: 1.5rem; 
    }

    .weightlifting_benefits_athletes .weightlifting_young_athletes p {
        font-size: 0.8rem;
    }

    .weightlifting_benefits_athletes h4 {
        font-size: 0.9rem;
    }

    .weightlifting_benefits_athletes img{
        width: 15.62px;
        height: 15.62px;
    }
}
 
@media screen and (max-width: 385px) {
    .weightlifting_benefits_athletes .weightlifting_young_athletes h1 {
        font-size: 1.3rem; 
    }

    .weightlifting_benefits_athletes .weightlifting_young_athletes p {
        font-size: 0.8rem;
    }

    .weightlifting_benefits_athletes h4 {
        font-size: 0.8rem;
    }

    .weightlifting_benefits_athletes img{
        width: 14.62px;
        height: 14.62px;
    }
}
 