.teenage_takeoff {
    margin-top: 9rem;
    margin-bottom: 1rem;
    padding-top: 5rem;
    padding-bottom: 5rem; 
    width: 100%;
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: column; 
    position: relative;
    height: 700px; 
    background-image: url('../../../../public/images/creatives/teenage_bg_banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hide_p { 
   padding-bottom: 7.7rem;
}
 
.img_effect::-webkit-scrollbar {
    width: 1px;
}
  
.img_effect::-webkit-scrollbar-track {
    background-color: #ffffff;
}
  
.img_effect::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 0px;
}
  
.img_effect::-webkit-scrollbar-thumb:hover {
    background-color: #ffffff;
}

.teenage_takeoff_content_component .hover-col .img-icon {
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    transform: translate(0, 0);
}

.teenage_takeoff_content_component .hover-col .img-icon:hover { 
    transform: translate(-3%, -3%) scale(1.03);
    transition: transform 0.2s ease-in-out;
}

.teenage_takeoff_content_component { 
    position: relative;
    width: 100%;
    max-width: 1943px;
    padding-bottom: 15rem;
    padding-top: 9.7rem; 
    height: auto;
}
 
.teenage_takeoff_content_component .img-icon {
    position: absolute; 
    height: auto;
}
 
.teenage_takeoff_content_component h2 {
    color: #191919;
    font-size: 38px;
    font-weight: 700; 
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif !important;
}

.teenage_takeoff_content_component .skew-btn {
    font-size: 15px;
}

.teenage_takeoff_content_component p {
    font-size: 19.2px;
    color: #5A5A5A;
    font-weight: 500; 
} 
.teenage_takeoff_content_component .list-item-para {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #5A5A5A;
}

.teenage_takeoff_content_component .list-item-para span {
    font-weight:400;
} 

@media screen and (max-width: 2700px) {  
    .teenage_takeoff { 
        margin-top: 13rem !important;
        margin-bottom: 10rem !important;
    }
}
 
@media screen and (max-width: 1922px) {
    .teenage_takeoff_content_component {
        padding-bottom: 12.6rem;
        padding-top: 6.9rem;
    }
    .teenage_takeoff {
        margin-top: 13rem;
        margin-bottom: 7rem;
    } 
    .img_effect { 
        overflow-x: hidden;  
    } 
}

@media screen and (max-width: 1600px) {
    .teenage_takeoff_content_component {
        padding-bottom: 8.5rem !important;
        padding-top: 3.9rem !important;
    }
    .teenage_takeoff {
        margin-top: 8rem;
        margin-bottom: 5rem;
    }
}

@media screen and (max-width: 1500px) {
    .teenage_takeoff_content_component {
        padding-bottom: 7.4rem !important;
        padding-top: 1.7rem !important;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
        height: 600px;
    }
}


@media screen and (max-width: 1457px) {
    .teenage_takeoff_content_component {
        padding-bottom: 5.8rem !important;
        padding-top: 1.7rem !important;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
}


@media screen and (max-width: 1400px) {
    .teenage_takeoff_content_component {
        padding-bottom: 4.5rem !important;
        padding-top: 1.7rem !important;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 1330px) {
    .teenage_takeoff_content_component {
        padding-bottom: 3.6rem !important;
        padding-top: 0.1rem !important;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
}
 
@media screen and (max-width: 1300px) {
    .teenage_takeoff_content_component {
        padding-bottom: 3.5rem !important;
        padding-top: 0.2rem !important;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
}
   
@media screen and (max-width: 1240px) {
    .teenage_takeoff_content_component {
        padding-bottom: 3rem !important;
        padding-top: 0.1rem !important;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .teenage_takeoff_content_component h2 {
        font-size: 1.86rem;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.86rem;
    }
}

@media screen and (max-width: 1199px) {
    .teenage_takeoff_content_component .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .teenage_takeoff_content_component {
        padding-bottom: 3.3rem !important;
        padding-top: 0.2rem !important;
    } 
    .teenage_takeoff_content_component h2 {
        font-size: 1.81rem;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.78rem;
    }
}

@media screen and (max-width: 1150px) {
    .teenage_takeoff_content_component .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .teenage_takeoff_content_component {
        padding-bottom: 3.9rem !important;
        padding-top: 0.2rem !important;
    } 
    .teenage_takeoff_content_component h2 {
        font-size: 1.74rem;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.71rem;
    }
}

@media screen and (max-width: 1100px) {
    .teenage_takeoff_content_component .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
        height: 460px !important;
    }
    .teenage_takeoff_content_component {
        padding-bottom: 3.5rem !important;
        padding-top: 0.2rem !important;
    } 
    .teenage_takeoff_content_component h2 {
        font-size: 1.56rem;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.65rem;
    }
}

@media screen and (max-width: 1140px) {
    .teenage_takeoff_content_component .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .teenage_takeoff_content_component {
        padding-bottom: 3.5rem !important;
        padding-top: 0.2rem !important;
    } 
    .teenage_takeoff_content_component h2 {
        font-size: 1.56rem;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.65rem;
    }
}


@media screen and (max-width: 1093px) {
    .teenage_takeoff_content_component .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .teenage_takeoff_content_component {
        padding-bottom: 3.7rem !important;
        padding-top: 0.2rem !important;
    } 
    .teenage_takeoff_content_component h2 {
        font-size: 1.50rem !important;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.65rem;
    }
}

@media screen and (max-width: 1050px) {
    .teenage_takeoff_content_component .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .teenage_takeoff_content_component {
        padding-bottom: 2.8rem !important;
        padding-top: 0.2rem !important;
    } 
    .teenage_takeoff_content_component h2 {
        font-size: 1.50rem !important;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.65rem;
    }
}

@media screen and (max-width: 1040px) {
    .teenage_takeoff_content_component .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .teenage_takeoff_content_component {
        padding-bottom: 2.8rem !important;
        padding-top: 0.2rem !important;
    } 
    .teenage_takeoff_content_component h2 {
        font-size: 1.54rem !important;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.64rem !important;
    }
}


@media screen and (max-width: 1024px) {
    .teenage_takeoff_content_component .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .teenage_takeoff_content_component {
        padding-bottom: 3.1rem !important;
        padding-top: 0.2rem !important;
    } 
    .teenage_takeoff_content_component h2 {
        font-size: 1.50rem !important;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.61rem !important;
    }
}

@media screen and (max-width: 1010px) {
    .teenage_takeoff_content_component .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .teenage_takeoff {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .teenage_takeoff_content_component {
        padding-bottom: 2.8rem !important;
        padding-top: 0.2rem !important;
    } 
    .teenage_takeoff_content_component h2 {
        font-size: 1.50rem !important;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.61rem !important;
    }
}

@media screen and (max-width: 991px) {
    .teenage_takeoff_content_component .skew-btn {
      font-size: 17px;
      padding: 5px 35px;
    }
    .teenage_takeoff_content_component h2 {
        font-size: 2rem !important;
    }
    .teenage_takeoff_content_component p {
        font-size: 1rem !important;
    }
    .teenage_takeoff_content_component p {
        font-size: 1rem;
    }  
    .teenage_takeoff {
        height: 1200px !important;
        margin-top: 18rem;
    }
    .teenage_takeoff_content_component .img-icon {
        position: relative;
        margin-top: -17.7%;
    }
}

@media screen and (max-width: 767px) {
  .teenage_takeoff_content_component  .skew-btn {
    font-size: 15px;
    padding: 4px 30px;
  }
  .teenage_takeoff {
    margin-top: 9rem !important;  
    height: 1300px !important;
  }
}

@media screen and (max-width: 700px) { 
    .teenage_takeoff { 
      height: 1330px !important;
    }
}

@media screen and (max-width: 600px) {  
    .teenage_takeoff_content_component h2 {
        font-size: 1.5rem !important;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.84rem !important;
    }
    .teenage_takeoff { 
      height: 1000px !important;
    }
} 

@media screen and (max-width: 430px) { 
    .teenage_takeoff_content_component h2 {
        font-size: 1.34rem !important;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.81rem !important;
    }
}

 
@media screen and (max-width: 350px) { 
    .teenage_takeoff_content_component h2 {
        font-size: 1.15rem !important;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.75rem !important;
    }
}

@media screen and (max-width: 400px) {
    .teenage_takeoff_content_component  .skew-btn {
    font-size: 12px;
    padding: 5px 25px;
  }
  .teenage_takeoff {
    margin-top: 7rem !important;  
  } 
    .teenage_takeoff_content_component h2 {
        font-size: 1.2rem !important;
    }
    .teenage_takeoff_content_component p {
        font-size: 0.76rem !important;
    }
}

@media screen and (max-width: 1850px) {
    .teenage_takeoff_content_component {
        padding-bottom: 12.6rem;
        padding-top: 6.9rem;
    }
}


@media screen and (max-width: 1800px) {
    .teenage_takeoff_content_component {
        padding-bottom: 12rem;
        padding-top: 6.9rem;
    }
}

@media screen and (max-width: 1732px) {
    .teenage_takeoff_content_component {
        padding-bottom: 11.5rem;
        padding-top: 6.8rem;
    }
}

@media screen and (max-width: 1720px) {
    .teenage_takeoff_content_component {
        padding-bottom: 11.2rem;
        padding-top: 6rem;
    }
}

@media screen and (max-width: 1688px) {
    .teenage_takeoff_content_component {
        padding-bottom: 10.3rem;
        padding-top: 5.5rem;
    }
}


@media screen and (max-width: 1665px) {
    .teenage_takeoff_content_component {
        padding-bottom: 9.5rem;
        padding-top: 5.5rem;
    }
}

@media screen and (max-width: 1599px) {
    .teenage_takeoff_content_component {
        padding-bottom: 9.2rem;
        padding-top: 5rem;
    }
}

@media screen and (max-width: 1584px) {
    .teenage_takeoff_content_component {
        padding-bottom: 9.1rem;
        padding-top: 3.7rem;
    }
}

@media screen and (max-width: 1555px) {
    .teenage_takeoff_content_component {
        padding-bottom: 7.4rem;
        padding-top: 3.7rem;
    }
}

@media screen and (max-width: 1484px) {
    .teenage_takeoff_content_component {
        padding-bottom: 6.8rem;
        padding-top: 2.3rem;
    }
}
 
@media screen and (max-width: 1436px) {
    .teenage_takeoff_content_component {
        padding-bottom: 6.3rem;
        padding-top: 2.3rem;
    }
}

@media screen and (max-width: 1384px) {
    .teenage_takeoff_content_component {
        padding-bottom: 5.9rem;
        padding-top: 2.1rem;
    }
}


@media screen and (max-width: 1350px) {
    .teenage_takeoff_content_component {
        padding-bottom: 5.2rem;
        padding-top: 1.8rem;
    }
}

@media screen and (max-width: 1370px) {
    .teenage_takeoff_content_component {
        padding-bottom: 4.1rem !important; 
        padding-top: 1.8rem !important;
    }
}

@media screen and (max-width: 1320px) {
    .teenage_takeoff_content_component {
        padding-bottom: 3.7rem !important; 
        padding-top: 1.8rem !important;
    }
}

@media screen and (max-width: 1300px) {
    .teenage_takeoff_content_component {
        padding-bottom: 4.7rem;
        padding-top: 1.5rem;
    }
    .teenage_takeoff {
        height: 540px;
    }
}

@media screen and (max-width: 1205px) {
    .teenage_takeoff_content_component {
        padding-bottom: 4rem;
        padding-top: 1rem;
    }
}
 
@media screen and (max-width: 1196px) {
    .teenage_takeoff_content_component {
        padding-bottom: 3.9rem;
        padding-top: 0.1rem;
    }
}

@media screen and (max-width: 1169px) {
    .teenage_takeoff_content_component {
        padding-bottom: 3.3rem; 
    }   
}

@media screen and (max-width: 1123px) {
    .teenage_takeoff_content_component { 
        padding-bottom: 2.9rem; 
    }   
    .teenage_takeoff_content_component h2 {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 1075px) {
    .teenage_takeoff_content_component { 
        padding-bottom: 2.4rem; 
    }   
}

@media screen and (max-width: 1040px) {
    .teenage_takeoff_content_component { 
        padding-bottom: 2.9rem; 
    }    
    .teenage_takeoff_content_component h2 {
        font-size: 1.5rem;
    }
    .teenage_takeoff_content_component p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1024px) {
    .teenage_takeoff_content_component { 
        padding-bottom: 2.7rem; 
    }    
    .teenage_takeoff_content_component h2 {
        font-size: 1.5rem;
    }
    .teenage_takeoff_content_component p {
        font-size: 1rem;
    } 
}

@media screen and (max-width: 767px) { 
   .teenage_takeoff_content_component p {
        font-size: 1rem;
    }
    .teenage_takeoff {
        height: 750px;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 500px) { 
   .teenage_takeoff_content_component p {
        font-size:1rem;
    }
    .teenage_takeoff_content_component h2 {
        font-size: 1.4rem;
    }
    .teenage_takeoff { 
        height: 900px !important;
    }  
}

@media screen and (max-width: 450px) { 
   .teenage_takeoff_content_component p {
        font-size: 1rem;
    }
    
    .teenage_takeoff { 
        height: 900px !important;
    }  
}

@media screen and (max-width: 380px) { 
    .teenage_takeoff_content_component p {
        font-size: 0.9rem;
    }
    .teenage_takeoff { 
        height: 780px !important;
    } 
 }
  