  .header { 
    z-index: 99;
    background: rgba(0, 0, 0, 0.367);
    transition: transparent 0.3s ease;
  }
 
  .header .skew-btn {
    font-size: 14px;
  }

  .active-header {
    background-color: #dcdcdc86;
    background: #fff;
  }
  
  .navbar-brand img {
    height: 50px;
  }
  
  .navbar-toggler {
    border: none;
  }

  .header .skew-btn a {
    text-decoration: none;
    color: #E9D06B;
  }

  .header .skew-btn a:active {
    color: #000000;
  }

  .header .skew-btn a:hover {
    color: #000000;
  }
  
  .navbar-toggler:focus {
    outline: none;
  }

  .navbar-toggler-icon {
    height: 25px;
  }
  
  .close-button {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
  }

  .navbar-nav .nav-link {
    font-size: 13px; 
    color: #ffffff;
    text-transform: uppercase;
    margin-right: 15px;
    font-weight: 600; 
    transition: color 0.3s ; 
    cursor: pointer;
    user-select: none;
  }
  
  .navbar-nav .nav-link:hover {
    color: #E9D06B; 
    text-underline-offset: 5px;
    transition: color 0.3s ; 
    font-weight: 600; 
    text-decoration: underline;
    text-decoration-thickness: 2px; 
  }

  .header .navbar-brand img {
    cursor: pointer;
    padding: 4px;
  }

  .navbar-nav .active {
    color: #E9D06B; 
    text-underline-offset: 5px;
    font-weight: 600; 
    text-decoration: underline;
    text-decoration-thickness: 2px; 
  }
 
  .navbar-actions .active-btn a {
    text-decoration: none; 
  }

  .navbar-actions .active-btn a:hover {
    text-decoration: none;
    color: #fff ;  
  }

  .dropdown-menu-programs {
    background-color: #ffffff;
    z-index: -1;
    position: absolute;
    margin-top: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;  
    transform: scale(1.05);
    box-shadow: 0 7px 22px -5px rgba(6, 6, 6, 0.416);
    border-radius: 3px;
    transition: 0.1s ease-in-out;  
  }
  
  .dropdown-menu-programs.show {
    display: block;
    animation: zoom-out 0.1s ease-out;
  }
  
  @keyframes zoom-out {
    from {
      transform: scale(1);
      opacity: 0;
    }
    to {
      transform: scale(1.10);
      opacity: 1;
    }
  }
 
  .dropdown .dropdown-toggle .dropdown-menu-programs:focus {
    transform: scale(1.05);
    transition: 0.3s ease-in-out;
  }

  .dropdown-menu-programs .dropdown-item{
    font-weight: 600;
    color: #000000;
    font-size: 0.96rem !important;
    padding: 0.15rem 2rem 0.1rem 1.2rem;
    transition: 0.2s ease-in-out;
  }

  .dropdown-menu-programs .dropdown-item.active {
    color: #E9D06B;
    background-color: transparent !important;
    text-decoration: none;
  }

  .dropdown-menu-programs .dropdown-item:hover {   
    color: #E9D06B;
    background-color: #fff;
    transition: 0.1s ease-in-out; 
    border-radius: 3px;
  }

  .scroll-hovered { 
    background: #fff;
    z-index: 999;
    transition: ease-in-out 0.2s;
  }

  .scroll-hovered .navbar-nav .nav-link {
    color: #000000;
  }

  .scroll-hovered .navbar-nav .nav-link:hover {
    color: #E9D06B;
  }

  .scroll-hovered .navbar-nav .active {
    color: #E9D06B;
  }

  .scroll-hovered .navbar-actions .default-btn .skew-btn {
    color: #FFFFFF ;
  }
 
  .navbar-nav .active-scroll {
    color: #E9D06B;
    background-color: #000000;
    text-underline-offset: 5px;
    font-weight: 600; 
    text-decoration: underline;
    text-decoration-thickness: 2px; 
  }
 
  @media (max-width: 991px) {

    .menu-container {
      padding-left: 0;
      width: 100%;
    }
    
    .menu-container .navbar-nav .nav-link { 
      padding: 4px 30px;
    } 
 
    .navbar-toggler {
      display: block;
    }

    .navbar-collapse {
      position: fixed;
      top: 0;
      flex-direction: column;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #F9F9F9;
      overflow: auto;
      transform: translateX(100%);
      transition: transform 0.4s ease-in-out;
      z-index: 9;
    }

    .navbar-collapse.show {
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 2rem;
      transform: translateX(0%);
    }
    
    .navbar-nav {
      width: 100%;
      text-align: left;
      margin-top: 80px; 
    }

    .navbar-nav .nav-item {
      display: block; 
      margin: 12px 0;
      position: relative;
    }

    .navbar-nav .ul-btn {
      margin: 0 30px;
      padding: 0.5rem 2rem 0.5rem 2rem;
      margin-top: 1rem;
    }

    .navbar-nav .nav-item::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px; 
      width: 100%;
      height: 1px;
      background-color: #ccc;  
    }

    .navbar-nav .dropdown-menu-programs {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: block;
      float: none;
      min-width: 200px; 
      margin: 0;
      font-size: 1rem;
      color: #5A5A5A;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, .15); 
      border-radius: .25rem;
    }
    
    .navbar-nav .dropdown-menu-programs .dropdown-item {
      display: block;
      width: 100%;
      padding: 0.25rem 0.65rem ;
      clear: both;
      font-weight: 600;
      color: #5A5A5A;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
    }
    
    .navbar-nav .dropdown-menu-programs .dropdown-item:hover,
    .navbar-nav .dropdown-menu-programs .dropdown-item:focus {
      color: #fff;
      background-color: #000000;
    }

    .navbar-nav .nav-link {
      color: #000000;
      font-weight: 700;
    }

    .navbar-nav .nav-link.active {
      color: #E9D06B ;
    }
}