.training_hub_students {
    user-select: none;
    margin-top: 4rem;
    margin-bottom: 8rem;
}

.training_hub_students h1 {
    font-size: 2.2rem;
    color:#000000;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
}
  
.popup-img .swiper-button-next, .popup-img .swiper-button-prev {
    color: #ffffff ; 
}
 
.popup-img .swiper-backface-hidden .swiper-slide {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.training_hub_students .gallery-img {
    transition: 0.2s ease-in-out;
    padding: 0;
    margin: 0;
}

.training_hub_students .gallery-img:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.training_hub_students h1 span {
    display: block;
}

@media screen and (max-width: 991px) {
    .training_hub_students h1 {
        font-size: 2.1rem;
    }
}

@media screen and (max-width: 770px) {
    .training_hub_students h1 span{
        display: inline-block;
    }
}

@media screen and (max-width: 507px) {
    .training_hub_students h1 {
        font-size: 1.9rem;
    }
}

@media screen and (max-width: 460px) {
    .training_hub_students h1 {
        font-size: 1.7rem;
    }
}

@media screen and (max-width: 380px) {
    .training_hub_students h1 {
        font-size: 1.6rem;
    }
}