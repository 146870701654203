   
/* -------- */ 

 .quest_bg { 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}

.mob-slider .card {
  box-shadow: 0px 0px 10px 1px rgba(108, 117, 125, 0.15); 
}
 
.new_accomplishment .slider-wrapper-accomplishments {
    background-color: #e9e9e9;
}
 

 .quest_accomplishments .quest_accomplishments_list .swiper-slide-group {
    margin-bottom: 1rem;
 }

 .mob-slider .slider-wrapper-accomplishments .card {
    margin-bottom: 1rem;
 }

.mob-slider .slider-wrapper-accomplishments .card {
    background-color: #fff;
    border-radius: 15px;
}

.slider-wrapper-accomplishments .accomplishments_info h1 {
    color: #ffffff;
}

.mob-slider .slider-wrapper-accomplishments .card .accomplishments_info h1 {
    color: #000000;
}

.quest_accomplishments .swiper-pagination-bullet-active, .mob-slider  .swiper-pagination-bullet-active{
    background-color: #ffffff;
}
 
.quest_accomplishments .swiper-pagination-bullet, .mob-slider .swiper-pagination-bullet{
    background-color: #707070;
}

.quest_accomplishments .swiper-button-next, .quest_accomplishments .swiper-button-prev,
.mob-slider .swiper-button-next, .quest_accomplishments .swiper-button-prev {
    color: #707070 ; 
}

  .quest_accomplishments .acomplishments::-webkit-scrollbar {
    width: 1px;
    background-color: transparent;
  }

  .quest_accomplishments .list-item-accomp {
    font-size: 0.8rem;
    margin-bottom: 1px; 
    color: #000000 !important;
  }

  .mob-slider .card  .list-item-accomp {
    color: #000000;
  }
  
  .quest_accomplishments .acomplishments::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
  }
  
  .quest_accomplishments .acomplishments::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
  }
  
  .quest_accomplishments .acomplishments::-webkit-scrollbar-track {
    background-color: transparent;
  }
 
.quest_accomplishments .quest_accomplishments_list {
    z-index: 2;
    position: relative; 
    margin-top: -550px; 
} 

.quest_accomplishments .acomplishments {
  height: 450px;
  overflow: auto; 
}
 
.quest_accomplishments .acomplishments {
  padding: 50px 40px;
  box-shadow: 0 3px 20px -2px rgba(108, 117, 125, 0.15);
  background: #fff; 
  position: relative; 
  text-align: center;
  user-select: none;
  border-radius: 15px; 
}

.quest_accomplishments .accomplishments_col {
  padding-right: 13.3px;
  padding-left: 13.3px;
}
  
.quest_accomplishments .quest_accomplishments_list .quest_info h1{
  color: #ffffff;
  font-weight: 700; 
}

.quest_accomplishments h3 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 20px;
  color: #000000;
  text-align: center;
}
    
  .quest_accomplishments ul { 
    list-style: none;
    color: #000000; 
    line-height: 20px;
    font-weight: 500;
  }
  
  .quest_accomplishments ul li {
    padding: 4px 0; 
    text-align: center; 
  }

  .quest_accomplishments .acomplishments .year, .mob-slider .card .year  {
    color: #000000;
    font-size: 2.4em;
    font-weight: 700;
  }

  .quest_accomplishments .acomplishments .year_subtitle, .mob-slider .card .year_subtitle {
    color: #000000;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 1rem;
  }

  
  .quest_accomplishments .featured {
    z-index: 10; 
    transform: scale(1.20);
  }
 
  @media (max-width:1200px) {
    .quest_accomplishments .accomplishments_col {
        padding-right: 11px;
        padding-left: 11px;
     }
  }
   
  @media (max-width: 992px) {
    .quest_bg {
      height: 500px;
    }
    .mob-slider .slider-wrapper-accomplishments {  
      margin-top: -450px; ; 
    } 
  }
  