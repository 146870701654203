/* CSS for the Country Picker Component */
 
.country_picker label[for="country"] {
    margin-right: 10px;  
}
   
.country_picker   #country {
    padding: 5px;
    font-size: 16px;
}
   
.country_picker label[for="province"] {
    margin-top: 10px;  
    font-weight: 500;
}

.country_picker  #province {
    margin-top: 5px;  
    padding: 5px;
    font-size: 16px;
}