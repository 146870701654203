  .offering_quality_experience {
    user-select: none;
    padding-top: 2rem;
    padding-bottom: 8rem;
    background-color: #F6F5F8;
  }

  .offering_quality_experience .card {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  .bullet-point {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #5A5A5A;
    margin-right: 15px;
  }

  .offering_quality_experience .list-items { 
    height: 100px;
    overflow: auto;
  }

  .offering_quality_experience .list-items::-webkit-scrollbar {
    width: 2px; 
  }
  
  .offering_quality_experience .list-items::-webkit-scrollbar-track {
    background: #ffffff;  
  }
  
  .offering_quality_experience .list-items::-webkit-scrollbar-thumb {
    background-color: #E9D06B;  
    border-radius: 50%;   
  }
  
  .offering_quality_experience .list-items::-webkit-scrollbar-thumb:hover {
    background-color: #E9D06B;  
  }

  .offering_quality_experience .list-items p {
    margin-bottom: 0;
    font-weight: 500;
  }

  .offering_quality_experience .quality_exp {
    height: 8.3rem; 
  }

  .offering_quality_experience .card:hover {
    transform: scale(1.05);
  }
  
  .offering_quality_experience .quality_experience h1{
    color: #000000;
    font-weight: 700;
  }
  
  .offering_quality_experience .quality_exp h2 {
    margin-top: 10px;
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-weight: 700;
    color: #000000;
  }
   
  .offering_quality_experience .quality_experience p {
    color: #5A5A5A;
    font-weight: 500;
    margin-left: 10rem;
    margin-right: 10rem;
    font-size: 0.9rem;
  }
 
  .offering_quality_experience .quality_exp { 
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .offering_quality_experience .container {
    max-width: 960px;
    margin: 0 auto; 
  }
 
  .offering_quality_experience .card {
    background-color: #ffffff;  
    border-radius: 0px;  
    border: none; 
    height: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.13);
  }

  .offering_quality_experience .card p {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .offering_quality_experience .skew-btn {
    padding: 5px 35px;
    font-size: 17px; 
  }

  .offering_quality_experience .card .thumbnail-icon {
    margin-bottom: 10px;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0; 
  }
 
  .offering_quality_experience .card h1 {
    margin-top: 10px;
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000000;
  }
  
@media screen and (max-width: 1199px) { 
  .offering_quality_experience .quality_experience p, .offering_quality_experience .card p  {
    font-size: 1rem;
  }

  .offering_quality_experience .quality_experience p {
    margin-left:7rem;
    margin-right:7rem;
  }
  .offering_quality_experience .quality_exp {
    height: 12rem;
  }
}

@media screen and (max-width: 991px) { 
  .offering_quality_experience .quality_experience p, .offering_quality_experience .card p {
    font-size: 1rem;
  }
  .offering_quality_experience .quality_experience p {
    margin-left: 5rem;
    margin-right: 5rem;
  } 
  .offering_quality_experience .quality_exp {
    height: 8rem;
  }
}

@media screen and (max-width: 767px) { 
  .offering_quality_experience .quality_experience p, .offering_quality_experience .card p {
    font-size: 0.9rem;
  }
  .offering_quality_experience .quality_experience p {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .offering_quality_experience .quality_experience h1 {
    font-size: 1.3rem;
  }
  .offering_quality_experience .quality_experience p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 500px) {
  .offering_quality_experience .card h1 {
    font-size: 1.2rem;
  } 
  .offering_quality_experience .quality_experience p, .offering_quality_experience .card p {
    font-size: 0.8rem;
  }

  .offering_quality_experience   .skew-btn {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .offering_quality_experience .card h1 {
    font-size: 1rem;
  } 
  .offering_quality_experience .quality_experience p, .offering_quality_experience .card p {
    font-size: 0.7rem;
  }
  .offering_quality_experience   .skew-btn {
    font-size: 15px;
  }
}
 
