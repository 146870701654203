.quest_blogs_section {
    background-color: #FFFFFF;
    user-select: none;
    cursor: pointer;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .social_media_post { 
    background-color: #FFFFFF;
    user-select: none;
    cursor: pointer; 
    position: relative; 
    transform: translateY(-100px);
    width: 80%;
    padding-bottom: 2.7rem;
    padding-top: 1.5rem; 
    margin: 0 auto;
    border: 1px solid white;
    border-radius: 25px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 8px 52px -6px rgba(0, 0, 0, 0.2);
  }

  .rcnt_blg {
    cursor: pointer;
  }
 
  .spinner_tweets {
    border: 4px solid #000000 ;
    border-top-color: #000000;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    animation: spinner-rotate 1s linear infinite;
    position: absolute;
  } 

  .insta-container {
    width: 100%;
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: auto;
    max-height: 400px;
    overflow-y: scroll;
    flex-direction: column; 
    align-items: center;
  }

  .social_media_post .heading-crd {
    border: 0;
  }

  .social_media_post .heading-crd .skew-btn {
   width: 200px;
  }

  .social_media_post .heading-crd h1 {
    color: #000000;
    font-weight: 700;
  }
 
  .quest_blogs_section .timing_info {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .quest_blogs_section .blog_short_info { 
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .quest_blogs_section .container {
    max-width: 960px;
    margin: 0 auto; 
  }
 
  .quest_blogs_section .card {
    background-color: #ffffff;  
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none; 
    border: 1px solid #C7C7C7;   
  }

  .social_media_post .twitter-tweet {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }

  .social_media_post .tweet-container {
    display: flex; 
    width: 100%;
    flex-direction: column;
    height: auto;
    max-height: 400px;
    overflow-y: scroll;  
  }
 

  .social_media_post .tweet-container::-webkit-scrollbar, 
  .social_media_post .insta-container::-webkit-scrollbar {
    width: 2px;
  }
  
  .social_media_post .tweet-container::-webkit-scrollbar-track,
  .social_media_post .insta-container::-webkit-scrollbar-track {
    background-color: rgb(255, 255, 255);
  }
  
  .social_media_post .tweet-container::-webkit-scrollbar-thumb,
  .social_media_post .insta-container::-webkit-scrollbar-thumb {
    background-color: #1D9BF0;
    border-radius: 50px;
  }

  .card-twt {
    border: 0 ;
    display: flex;
    align-items: center;
    justify-content: center; 
  }
  
  .quest_blogs_section .heading-arctcls {
    margin-bottom: 2rem;
    font-weight: 700;
    text-align: center;
    color: #000000;
  }

  .quest_blogs_section .card p {
    font-size: 0.8rem;
    font-weight: 500;
    color: #5A5A5A !important;
  }

  .quest_blogs_section .card img {
    margin-bottom: 10px;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0; 
  }

  .quest_blogs_section .link-tag-card {
    text-decoration: none;
    color: #5A5A5A;
  }
  
  .quest_blogs_section .card h1 {
    margin-top: 10px;
    font-size: 0.9rem;
    margin-bottom: 10px;
    font-weight: 700;
    color: #000000;
    line-height: 1.5;
  }

  .inner_blogs_section { 
    position: relative; 
    width:auto; 
    height: auto;
    user-select: none;
    transform: translateY(-10%);
    margin: 0 auto;  
    padding: 3rem;  
    background-color: #FFFFFF;
  }

  .blog_inner_wrapper {
    background-color: #F6F5F8; 
    height: auto; 
  }

  .blog_inner_wrapper h1 {
    color: #5A5A5A;
    font-size: 1.6rem;
    font-weight: 700;
  }

  .blog_inner_wrapper h5 {
    font-size: 1.3rem;
    color: #5A5A5A;
  }

  .blog_inner_wrapper p{
    font-size: 1rem;

  }

  .inner_blogs_section h2 {
    font-size: 2rem;
    color: #000000;
    font-weight: 700;
    text-transform: uppercase; 
  }

  .inner_blogs_section h3 {
    font-size: 0.9rem;
    color: #5A5A5A;
    font-weight: 600;
    text-transform: uppercase; 
  }

  .inner_blogs_section h4 {
    font-size: 0.9rem;
    color: #5A5A5A;
    font-weight: 600;
    text-transform: uppercase; 
  }

  .inner_blogs_section h3 span {
    color: #6B6B6B;
    font-weight: 500;
  }

  .inner_blogs_section h4 span {
    display: inline-block; 
    margin-left: 12px;
  }

  .inner_blogs_section h4 span ul {
    display: flex;
    list-style: none;
  }

  .inner_blogs_section .para-normal {
    color: #5A5A5A;
    font-size: 1rem;
    font-weight: 500;
  }

  .inner_blogs_section .para-heading {
    color: #5A5A5A;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .inner_blogs_section h4 span ul li {
    margin-right: 10px;
  }

  .inner_blogs_section h4 span ul li img {
    width: 28px;
    cursor: pointer;
  }

  .inner_blogs_section h4 span ul li:last-child {
    margin-right: 0;
  }
  
@media screen and (max-width: 1199px) { 
  .quest_blogs_section .card p  {
    font-size: 1rem;
  }
}

@media screen and (max-width: 991px) { 
  .quest_blogs_section .card p {
    font-size: 1rem;
  }
  .quest_blogs_section {
    padding-bottom: 1rem;
  }
  .social_media_post .tweet-container {
    width: 80%;
  }
}

@media screen and (max-width: 767px) { 
  .quest_blogs_section .card p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 500px) {
  .quest_blogs_section .card h1 {
    font-size: 1.2rem;
  } 
  .quest_blogs_section .card p {
    font-size: 0.8rem;
  }
  .social_media_post .tweet-container {
    width: 100%;
  }
  .social_media_post {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .quest_blogs_section .card h1 {
    font-size: 1rem;
  } 
  .quest_blogs_section .card p {
    font-size: 0.7rem;
  }
}
 
