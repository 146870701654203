.un_cutted_img_component {
    padding-top: 3rem; 
    user-select: none;
    overflow-x: hidden;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.un_cutted_img_component h1 {
    color: #000000;
    font-size: 2.2rem;
    font-weight: 700; 
}

.un_cutted_img_component p {
    font-size: 1.2rem;
    color: #5A5A5A !important;
    font-weight: 500 !important;
}
  
.un_cutted_img_component .content_1_speed_strength_conditioning h3 {
    color: #000000;
    font-size: 1.5rem;
    font-weight: 700;
}

.un_cutted_img_component .list-items {
    list-style-type: none;
    padding: 0;
    font-size: 0.9rem;
    margin: 0;
}

.highlight_para_spn {
    font-weight: 700;
    font-size: 0.85rem;
    font-style: italic;
}

.bullet-point {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #5A5A5A;
    margin-right: 15px;
}

.un_cutted_img_component .list-items li {
    font-weight: 500 !important;
    color: #5A5A5A;
    font-size: 16.5px;
}
 
 .top-heading {
    color: #5A5A5A; 
 }
 
 .un_cutted_img_component .content_1_speed_strength_conditioning h3 {
    font-size: 1rem;
}
 
.un_cutted_img_component .list-items {
    /* columns: 2;
    column-gap: auto; */
}
 
@media screen and (max-width: 1490px) { 
    .un_cutted_img_component .list-items li {
        font-size: 0.9rem;
    } 
}

@media screen and (max-width: 1299px) { 
    .un_cutted_img_component .list-items li {
        font-size: 0.8rem;
    } 
}

@media screen and (max-width: 1100px) {  
    .un_cutted_img_component .list-items li {
        font-size: 0.8rem;
    } 
}

@media screen and (max-width: 991px) { 
    .un_cutted_img_component .list-items li {
        font-size: 1rem;
    } 
}

@media screen and (max-width: 768px) {  
    .un_cutted_img_component .list-items li {
        font-size: 0.83rem;
    } 
    .un_cutted_img_component h1 {
        font-size: 1.7rem;
    }
}

@media screen and (max-width: 1200px){
    .un_cutted_img_component h1 {
        font-size: 1.6rem;
    }
    .un_cutted_img_component p {
        font-size: 0.9rem;
    }
    .un_cutted_img_component .skew-btn {
        font-size: 16px;
    }
    .un_cutted_img_component .content_1_speed_strength_conditioning h3 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 577px){
    .un_cutted_img_component h1 {
        font-size: 1.4rem;
    }
    .un_cutted_img_component p {
        font-size: 1rem;
    }
    .un_cutted_img_component .skew-btn {
        font-size: 14px;
    }
    .un_cutted_img_component .content_1_speed_strength_conditioning h3 {
        font-size: 1.2rem;
    }
}


@media screen and (max-width: 500px){
    .un_cutted_img_component h1 {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 400px){ 
    .un_cutted_img_component p {
        font-size: 1rem;
    }
    .un_cutted_img_component .skew-btn {
        font-size: 13.5px;
    }
    .un_cutted_img_component .content_1_speed_strength_conditioning h3 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 380px) {
    .un_cutted_img_component p {
      font-size: 0.9rem;
    }
}


   