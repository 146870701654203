.important_factors_component { 
    user-select: none;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.important_factors_component .factors_info h1 {
    color: #000000;
    font-size: 2.2rem;
    font-weight: 700;
}

.important_factors_component .card { 
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    height: 200px;
}

.important_factors_component .card .card-body {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.important_factors_component .card:hover{
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
}

.important_factors_component .imp {
    color: #000000;
}

.important_factors_component .factors {
    color: #E9D06B;
}

.important_factors_component .factors_info p {
    color: #5A5A5A;
    font-size: 0.9rem; 
}

.important_factors_component .factors_content .card-title{
    color: #000000;
    font-weight: 700;
    font-size: 1.4rem;
    text-transform: uppercase;
}

.important_factors_component .factors_content .card-title span img {
    width: 56px;
    height: 56px;
}

.important_factors_component .factors_content .card-text {
    font-size: 0.9rem;
    font-weight: 500;
    color: #5A5A5A;
}

@media screen and (max-width: 520px) {
    .important_factors_component .card .card-body {
        padding-top: 1rem; 
    }
    .important_factors_component .card {
        height: 210px;
    }
}

@media screen and (max-width: 433px) {
   .important_factors_component .factors_info h1 {
        font-size: 1.5rem; 
    }

   .important_factors_component .factors_info p {
        font-size: 0.8rem;
    }

    .important_factors_component .factors_content .card-title {
        font-size: 1.1rem;
    }

    .important_factors_component .factors_content .card-text  { 
        font-size: 0.8rem;
    }

    .important_factors_component .factors_content .card-title span img {
        width: 48px;
        height: 48px;
    }
}
 
@media screen and (max-width: 385px) {
   .important_factors_component .factors_info h1 {
        font-size: 1.3rem; 
    }

   .important_factors_component .factors_info p {
        font-size: 0.8rem;
    }

    .important_factors_component .factors_content .card-title {
        font-size: 1rem;
    }

    .important_factors_component .factors_content .card-text  { 
        font-size: 0.8rem;
    }

    .important_factors_component .factors_content .card-title span img {
        width: 42px;
        height: 42px;
    }
}
 