.associations_swiper_slider_landing { 
    position: relative; 
    width: 80%;
    padding-bottom: 2rem;  
    margin: 0 auto;
    background-image: url('../../../../public/images/associations/associations_banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.associations_swiper_slider_landing h2 {
    color: #000000;
    font-size: 2.3rem;
    font-weight: 700;
}

.associations_swiper_slider_landing .container {
    max-width: 1200px;  
    margin: 0 auto;
}

.associations_swiper_slider_landing .asso_swiper .normal_w {
    width: 100%;
    height: 58px;
}

.associations_swiper_slider_landing .asso_swiper .bnc_logo { 
    width: 75px;
    height: 58px;
}

.associations_swiper_slider_landing .asso_swiper .opportunity_logo { 
    width: 105px;
    height: 58px;
}

.associations_swiper_slider_landing .asso_swiper {
    position: relative;
    z-index: 1;
    user-select: none;
    cursor: pointer; 
}
  
.associations_swiper_slider_landing .swiper-slide {
    text-align: center;
}
  
@media (max-width: 640px) {
    .associations_swiper_slider_landing .swiper-slide {
      width: 50%;
    }
    .associations_swiper_slider_landing h2 { 
        font-size: 1.5rem; 
    }
}
  
@media (min-width: 768px) {
    .associations_swiper_slider_landing .swiper-slide {
      width: 50%;
    }
    .associations_swiper_slider_landing .swiper-slide img {
        max-width: 100%;
        max-height: 100%;
    }
    .associations_swiper_slider_landing h2 { 
        font-size: 1.8rem; 
    }
}

@media (min-width: 1024px) {
    .associations_swiper_slider_landing .swiper-slide {
      width: 25%;
    }
    .associations_swiper_slider_landing .swiper-slide img {
        max-width: 100%;
        max-height: 100%;
    }
    .associations_swiper_slider_landing h2 { 
        font-size: 2.1rem; 
    }
}
