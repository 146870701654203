 

.img_effect::-webkit-scrollbar {
    width: 1px;
}
  
.img_effect::-webkit-scrollbar-track {
    background-color: #ffffff;
}
  
.img_effect::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 0px;
}
  
.img_effect::-webkit-scrollbar-thumb:hover {
    background-color: #ffffff;
}

.radial_img_parent_clock {
    margin-top: 9rem;
    margin-bottom: 1rem;
    padding-top: 15rem;
    padding-bottom: 15rem;
    height: auto;   
    width: 100%;
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: column; 
    position: relative;
    height: 674px; 
    background-color: #ffffff ; 
}

.hide_p { 
   padding-bottom: 7.7rem;
}

.radial_img_content_component_clock .list-item-para {
    margin-bottom: 0px;
}

.radial_img_content_component_clock .list-item-heading {
    font-size: 1rem;
    font-weight: 700;
    color: #000000;
}

.radial_img_content_component_clock .hover-col .img-icon {
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    transform: translate(0, 0);
}

.radial_img_content_component_clock .hover-col .img-icon:hover { 
    transform: translate(-3%, -3%) scale(1.03);
    transition: transform 0.2s ease-in-out;
}

.radial_img_content_component_clock { 
    position: relative;
    width: 100%;
    max-width: 1943px;
    padding-bottom: 10rem !important;
    padding-top: 5.1rem !important;
    background-color: #ffffff; 
    height: auto;
}
 
.radial_img_content_component_clock .img-icon {
    position: absolute; 
    height: auto;
}
 
.radial_img_content_component_clock h2 { 
    color: #000000;
    font-size: 38px;
    font-weight: 700; 
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif !important;
}

.radial_img_content_component_clock .skew-btn {
    font-size: 15px;
}

.radial_img_content_component_clock p {
    font-size: 19.2px;
    color: #5A5A5A;
    font-weight: 500;
} 
.radial_img_content_component_clock .list-item-para {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.radial_img_content_component_clock .list-item-para span {
    font-weight:500;
} 

@media screen and (max-width: 2700px) {  
    .radial_img_parent_clock { 
        margin-top: 13rem;
        margin-bottom: 8rem;
    }
}
 
@media screen and (max-width: 1922px) {
    .radial_img_content_component_clock {
        padding-bottom: 12.6rem;
        padding-top: 6.9rem;
    }
    .radial_img_parent_clock {
        margin-top: 13rem;
        margin-bottom: 7rem;
    } 
    .img_effect { 
        overflow-x: hidden;  
    } 
}

@media screen and (max-width: 1600px) {
    .radial_img_content_component_clock {
        padding-bottom: 5.9rem !important;
        padding-top: 1.9rem !important;
    }
    .radial_img_parent_clock {
        margin-top: 6rem;
        margin-bottom: 8rem;
    }
}

@media screen and (max-width: 1500px) {
    .radial_img_content_component_clock {
        padding-bottom: 4.9rem !important;
        padding-top: 1.2rem !important;
    }
    .radial_img_parent_clock {
        margin-top: 6rem;
        margin-bottom: 8rem;
    }
}

@media screen and (max-width: 1457px) {
    .radial_img_content_component_clock {
        padding-bottom: 3.6rem !important;
        padding-top: 0.1rem !important;
    }
    .radial_img_parent_clock {
        margin-top: 6rem;
        margin-bottom: 8rem;
    }
}


@media screen and (max-width: 1400px) {
    .radial_img_content_component_clock {
        padding-bottom: 3.5rem !important;
        padding-top: 0.05rem !important;
    }
    .radial_img_parent_clock {
        margin-top: 6rem;
        margin-bottom: 8rem;
    }
}
 
@media screen and (max-width: 1300px) {
    .radial_img_content_component_clock {
        padding-bottom: 11.9rem !important;
        padding-top: 0.1rem !important;
    }
    .radial_img_parent_clock {
        margin-top: 2rem;
        margin-bottom: 5rem;
    }
    .radial_img_content_component_clock h2 {
        font-size: 1.4rem;
    }
    .radial_img_content_component_clock p {
        font-size: 0.64rem;
    }
    .list-item-para {
        font-size: 0.65rem;
    }
}  

@media screen and (max-width: 1330px) {
    .radial_img_content_component_clock {
        padding-bottom: 3.7rem !important;
        padding-top: 0.05rem !important;
    }
    .radial_img_parent_clock {
        margin-top: 2rem;
        margin-bottom: 5rem;
    }
    .radial_img_content_component_clock h2 {
        font-size: 1.76rem;
    }
    .radial_img_content_component_clock p {
        font-size: 0.78rem;
    }
    .list-item-para {
        font-size: 0.6rem;
    }
}

@media screen and (max-width: 1200px) {
    .radial_img_content_component_clock {
        padding-bottom: 11.9rem !important;
        padding-top: 0.1rem !important;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock h2 {
        font-size: 1.5rem;
    }
    .radial_img_content_component_clock p {
        font-size: 0.64rem;
    }
    .list-item-para {
        font-size: 0.65rem;
    }
}

/* @media screen and (max-width: 1205px) {
    .radial_img_content_component_clock {
        padding-bottom: 11.9rem !important;
        padding-top: 0.1rem !important;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock h2 {
        font-size: 1.5rem;
    }
    .radial_img_content_component_clock p {
        font-size: 0.64rem;
    }
    .list-item-para {
        font-size: 0.65rem;
    }
} */

@media screen and (max-width: 1240px) {
    .radial_img_content_component_clock {
        padding-bottom: 8rem !important;
        padding-top: 0.1rem !important;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock h2 {
        font-size: 1.86rem;
    }
    .radial_img_content_component_clock p {
        font-size: 0.86rem;
    }
}

@media screen and (max-width: 1199px) {
    .radial_img_content_component_clock .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock {
        padding-bottom: 3.3rem !important;
        padding-top: 0.2rem !important;
    } 
    .radial_img_content_component_clock h2 {
        font-size: 1.4rem;
    }
    .radial_img_content_component_clock p {
        font-size: 0.6rem;
    }
    .list-item-para {
        font-size: 0.56rem;
    }
}

@media screen and (max-width: 1150px) {
    .radial_img_content_component_clock .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock {
        padding-bottom: 3.9rem !important;
        padding-top: 0.2rem !important;
    } 
    .radial_img_content_component_clock h2 {
        font-size: 1.74rem;
    }
    .radial_img_content_component_clock p {
        font-size: 0.71rem;
    }
}

@media screen and (max-width: 1100px) {
    .radial_img_content_component_clock .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock {
        padding-bottom: 3.5rem !important;
        padding-top: 0.2rem !important;
    } 
    .radial_img_content_component_clock h2 {
        font-size: 1.56rem;
    }
    .radial_img_content_component_clock p {
        font-size: 0.65rem;
    }
}

@media screen and (max-width: 1140px) {
    .radial_img_content_component_clock .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock {
        padding-bottom: 3.5rem !important;
        padding-top: 0.2rem !important;
    } 
    .radial_img_content_component_clock h2 {
        font-size: 1.56rem;
    }
    .radial_img_content_component_clock p {
        font-size: 0.65rem;
    }
}


@media screen and (max-width: 1093px) {
    .radial_img_content_component_clock .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock {
        padding-bottom: 3.7rem !important;
        padding-top: 0.2rem !important;
    } 
    .radial_img_content_component_clock h2 {
        font-size: 1.50rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.65rem;
    }
}

@media screen and (max-width: 1050px) {
    .radial_img_content_component_clock .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock {
        padding-bottom: 2.8rem !important;
        padding-top: 0.2rem !important;
    } 
    .radial_img_content_component_clock h2 {
        font-size: 1.50rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.65rem;
    }
}

@media screen and (max-width: 1040px) {
    .radial_img_content_component_clock .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock {
        padding-bottom: 2.8rem !important;
        padding-top: 0.2rem !important;
    } 
    .radial_img_content_component_clock h2 {
        font-size: 1.54rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.64rem !important;
    }
}


@media screen and (max-width: 1024px) {
    .radial_img_content_component_clock .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock {
        padding-bottom: 3.1rem !important;
        padding-top: 0.2rem !important;
    } 
    .radial_img_content_component_clock h2 {
        font-size: 1.50rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.61rem !important;
    }
}

@media screen and (max-width: 1010px) {
    .radial_img_content_component_clock .skew-btn {
      font-size: 15px;
      padding: 6px 40px;
    }
    .radial_img_parent_clock {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .radial_img_content_component_clock {
        padding-bottom: 2.8rem !important;
        padding-top: 0.2rem !important;
    } 
    .radial_img_content_component_clock h2 {
        font-size: 1.50rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.61rem !important;
    }
}

@media screen and (max-width: 991px) {
    .radial_img_content_component_clock  .skew-btn {
      font-size: 17px;
      padding: 5px 35px;
    }
    .radial_img_parent_clock {
        margin-top: 12rem !important;  
    } 
    .radial_img_content_component_clock h2 {
        font-size: 2rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 1rem !important;
    }
}

@media screen and (max-width: 767px) {
  .radial_img_content_component_clock  .skew-btn {
    font-size: 15px;
    padding: 4px 30px;
  }
  .radial_img_parent_clock {
    margin-top: 9rem !important;  
  }
}

@media screen and (max-width: 600px) {  
    .radial_img_content_component_clock h2 {
        font-size: 1.5rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.84rem !important;
    }
} 

@media screen and (max-width: 430px) { 
    .radial_img_content_component_clock h2 {
        font-size: 1.34rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.81rem !important;
    }
}

 
@media screen and (max-width: 350px) { 
    .radial_img_content_component_clock h2 {
        font-size: 1.15rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.75rem !important;
    }
}

@media screen and (max-width: 400px) {
    .radial_img_content_component_clock  .skew-btn {
    font-size: 12px;
    padding: 5px 25px;
  }
  .radial_img_parent_clock {
    margin-top: 7rem !important;  
  } 
    .radial_img_content_component_clock h2 {
        font-size: 1.2rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.76rem !important;
    }
}

@media screen and (max-width: 1850px) {
    .radial_img_content_component_clock {
        padding-bottom: 12rem;
        padding-top: 6.6rem;
    }
}


@media screen and (max-width: 1800px) {
    .radial_img_content_component_clock {
        padding-bottom: 12rem;
        padding-top: 6.9rem;
    }
}

@media screen and (max-width: 1732px) {
    .radial_img_content_component_clock {
        padding-bottom: 11.5rem;
        padding-top: 6.8rem;
    }
}

@media screen and (max-width: 1720px) {
    .radial_img_content_component_clock {
        padding-bottom: 11.2rem;
        padding-top: 6rem;
    }
}

@media screen and (max-width: 1688px) {
    .radial_img_content_component_clock {
        padding-bottom: 10.3rem;
        padding-top: 5.5rem;
    }
}


@media screen and (max-width: 1665px) {
    .radial_img_content_component_clock {
        padding-bottom: 9.5rem;
        padding-top: 5.5rem;
    }
}

@media screen and (max-width: 1599px) {
    .radial_img_content_component_clock {
        padding-bottom: 9.2rem;
        padding-top: 5rem;
    }
}

@media screen and (max-width: 1584px) {
    .radial_img_content_component_clock {
        padding-bottom: 9.1rem;
        padding-top: 3.7rem;
    }
}

@media screen and (max-width: 1555px) {
    .radial_img_content_component_clock {
        padding-bottom: 7.4rem;
        padding-top: 3.7rem;
    }
}

@media screen and (max-width: 1484px) {
    .radial_img_content_component_clock {
        padding-bottom: 6.8rem;
        padding-top: 2.3rem;
    }
}
 
@media screen and (max-width: 1436px) {
    .radial_img_content_component_clock {
        padding-bottom: 6.3rem;
        padding-top: 2.3rem;
    }
}

@media screen and (max-width: 1384px) {
    .radial_img_content_component_clock {
        padding-bottom: 5.9rem;
        padding-top: 2.1rem;
    }
}


@media screen and (max-width: 1350px) {
    .radial_img_content_component_clock {
        padding-bottom: 5.2rem;
        padding-top: 1.8rem;
    }
}

@media screen and (max-width: 1370px) {
    .radial_img_content_component_clock {
        padding-bottom: 2rem !important; 
        padding-top: 1.8rem !important;
    }
    .radial_img_content_component_clock h2 {
        font-size: 1.5rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.86rem !important;
    } 
}

/* @media screen and (max-width: 1300px) {
    .radial_img_content_component_clock {
        padding-bottom: 4.7rem;
        padding-top: 1.5rem;
    }
} */

@media screen and (max-width: 1205px) {
    .radial_img_content_component_clock {
        padding-bottom: 3.1rem !important;
        padding-top: 1rem !important;
    } 
    .radial_img_content_component_clock h2 {
        font-size: 1.3rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.70rem !important;
    } 
    .radial_img_content_component_clock .list-item-para {
        font-size: 0.6rem;
    }
}
 
@media screen and (max-width: 1196px) {
    .radial_img_content_component_clock {
        padding-bottom: 4.3rem;
        padding-top: 0.1rem;
    }
}

@media screen and (max-width: 1169px) {
    .radial_img_content_component_clock {
        padding-bottom: 4.3rem; 
    }   
}

@media screen and (max-width: 1123px) {
    .radial_img_content_component_clock { 
        padding-bottom: 4.2rem; 
    }   
    .radial_img_content_component_clock h2 {
        font-size: 1.8rem;
    }
    
    .radial_img_content_component_clock h2 {
        font-size: 1.3rem !important;
    }
    .radial_img_content_component_clock p {
        font-size: 0.70rem !important;
    } 
}

@media screen and (max-width: 1075px) {
    .radial_img_content_component_clock { 
        padding-bottom: 1.4rem !important; 
    }   
}

@media screen and (max-width: 1040px) {
    .radial_img_content_component_clock { 
        padding-bottom: 2.9rem; 
    }    
    .radial_img_content_component_clock h2 {
        font-size: 1.35rem;
    }
    .radial_img_content_component_clock p {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 1024px) {
    .radial_img_content_component_clock { 
        padding-bottom: 2.7rem; 
    }    
    .radial_img_content_component_clock h2 {
        font-size: 1.3rem;
    }
    .radial_img_content_component_clock p {
        font-size: 0.81rem;
    }
}

@media screen and (max-width: 991px) { 
   .radial_img_content_component_clock p {
        font-size: 1rem;
    } 
    .radial_img_parent_clock {
        height: auto;
        margin-top: 18rem;
    }
    .radial_img_content_component_clock .img-icon {
        position: relative;
        margin-top: -17.7%;
    }
}

@media screen and (max-width: 767px) { 
   .radial_img_content_component_clock p {
        font-size: 1rem;
    }
    .radial_img_parent_clock {
        height: auto;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 500px) { 
   .radial_img_content_component_clock p {
        font-size:1rem;
    }
    .radial_img_content_component_clock h2 {
        font-size: 1.4rem;
    }
    /* .radial_img_content_component_clock .skew-btn { */
        /* padding: 7px 40px; */
        /* font-size: 15px;
         */
    /* } */
}

@media screen and (max-width: 450px) { 
   .radial_img_content_component_clock p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 380px) { 
    .radial_img_content_component_clock p {
         font-size: 0.9rem;
     }
 }
  