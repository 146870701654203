.sales_contact_form {
    background-image: url('../../../../public/images/creatives/contact_sales.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding-bottom: 4rem;
    padding-top: 8rem;
    margin-bottom: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales_contact_form h2 {
    color: #E9D06B;
    font-weight: 700; 
    font-size: 2.2rem;
}

.sales_contact_form p {
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
}

.sales_contact_form .skew-btn {
    color: #000000 ;
    background-color: #E9D06B;
}

.sales_contact_form .skew-btn:hover {
    color: #E9D06B;
    background-color: #000000;
}

.sales_contact_form .contact_details {
    background-color: #fff;
    border-radius: 10px;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    padding-top: 1.2rem;
    padding-right: 1rem;
    padding-bottom: 2.2rem;
}

.sales_contact_form .contact_details .form-fields-contact button{
    color: #E9D06B !important;
    background-color: #000000 !important;
}

.sales_contact_form .contact_details .form-fields-contact button:hover{
    color: #000000 !important;
    background-color: #E9D06B !important;
}

.sales_contact_form .form-fields-contact {
    width: 100%;
    padding: 0;
    margin: 0;
}
 
.sales_contact_form .form-fields-contact .fields-contact input{
    width: 100%;
    padding: 0;
    margin: 0; 
}

.sales_contact_form .form-fields-contact .fields-contact input[type="text"],
.sales_contact_form .form-fields-contact .fields-contact input[type="email"] {
    box-sizing: border-box;
    width: 100%;
    padding: 13px;
    border: 1px solid #5A5A5A; 
    resize: none;
    font-size: 14px;
    border-radius: 3px;
    margin-bottom: 20px;
}

.sales_contact_form .form-fields-contact input:focus{
    outline: none;
}

.sales_contact_form .form-fields-contact textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 13px;
    border: 1px solid #9e9e9e; 
    resize: none;
    font-size: 14px;
    height: 120px;
    border-radius: 3px;
    margin-bottom: 20px;
}

@media (max-width: 600px){
    .sales_contact_form h2 {
        font-size: 1.8rem;
    }
    .sales_contact_form p {
        font-size: 0.9rem;
    }
}

@media (max-width: 400px){
    .sales_contact_form h2 {
        font-size: 1.6rem;
    }
    .sales_contact_form p {
        font-size: 0.8rem;
    }
}