.contact_section {
    position: relative; 
    width: 75%;
    padding-bottom: 2.7rem;
    padding-top: 1.5rem;
    user-select: none;
    transform: translateY(-30%);
    margin: 0 auto;
    border: 1px solid white;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 8px 12px -6px rgba(0, 0, 0, 0.2);
}
 
#get_in_touch {
  scroll-margin-top: 80px;  
}

.contact_section .contact-info h5 {
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
}

.contact_section .contact-info h1 {
  color: #000000;
  font-weight: 700;
  font-size: 3.1rem;
  margin-left: 2.2rem;
}

.contact_section .contact-info span {
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  vertical-align: top;
  margin-right: 5px;
  font-size: 1rem;
  color: #5A5A5A;
  font-weight: 400;
  text-decoration: none;
}

.contact_section .contact-info span a {
  color: #5A5A5A !important;  
  text-decoration: none; 
}

.contact_section .contact-info span a:hover {
  color: #5A5A5A !important; 
  text-decoration: none; 
}
  
  .contact_section .contact-info br {
    display: none;
  }

  .contact_section .form-fields-contact {
    width: 100%;
    padding: 0;
    margin: 0;
  }
 
  .contact_section .form-fields-contact .fields-contact input{
    width: 100%;
    padding: 0;
    margin: 0; 
  }

  .contact_section .form-fields-contact .fields-contact input[type="text"],
  .contact_section .form-fields-contact .fields-contact input[type="email"] {
    box-sizing: border-box;
    width: 100%;
    padding: 13px;
    border: 1px solid #5A5A5A; 
    resize: none;
    font-size: 14px;
    border-radius: 3px;
    margin-bottom: 20px;
  }

  .contact_section .form-fields-contact input:focus{
    outline: none;
  }

  .contact_section .form-fields-contact textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 13px;
    border: 1px solid #9e9e9e; 
    resize: none;
    font-size: 14px;
    height: 120px;
    border-radius: 3px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1428px) { 
    .contact_section .contact_info_cn h1{
      font-size: 2.8rem;
    }
  }
   
 
  @media screen and (max-width: 1316px) { 
    .contact_section {
      width: 80%;
    }
  }

  @media screen and (max-width: 1234px) { 
    .contact_section {
      width: 85%;
    }

    .contact_section .skew-btn  {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1164px) { 
    .contact_section {
      width: 85%;
    }

    .contact_section .contact-info h1 {
      font-size: 2.5rem;
    }

    .contact_section .skew-btn  {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 1065px) {  
    .contact_section .contact-info h1 {
      font-size: 2.2rem;
    }

    .contact_section .skew-btn  {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 991px) { 
    .contact_section {
      transform: translateY(-14%);
    }
    .contact_section .contact-info h1 {
      margin-left: 0;
    }
    .contact_section .location_w {
      width: 60%;
    }
  }

  @media screen and (max-width: 690px) {
    .contact_section .location_w {
      width: 80%;
    }
    .contact_section .contact-info span {
      font-size: 0.8rem;
    }
  }
   
  @media screen and (max-width: 550px) {
    .contact_section .location_w {
      width: 100%;
    }
    .contact_section .contact-info span {
      font-size: 0.8rem;
    }
  }
   
  @media screen and (max-width:380px) {  
    .contact_section .contact-info h1 {
      font-size: 1.9rem;
    }

    .contact_section .skew-btn  {
      font-size: 13px;
    }

    .contact_section .contact-info h5 {
      font-size: 0.9rem;
    }

    .contact_section .contact-info h5 span {
      font-size: 0.7rem;
    } 

    .contact_section .contact-info span {
      font-size: 0.8rem;
    }
    .contact_section .skew-btn  {
      font-size: 13px;
      padding: 5px 20px;
    }
    
  }