.slider-wrapper-news-articles {
    background-color: #FFFFFF;
    user-select: none;
    cursor: pointer;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    font-family: 'Montserrat', sans-serif !important;
  }

  .slider-wrapper-news-articles .new_articles h1{
    color: #000000;
    font-weight: 700;
  }
   
  .slider-wrapper-news-articles .new_articles p {
    color: #5A5A5A;
    font-weight: 500;
    font-size: 0.9rem;
  }

  .slider-wrapper-news-articles .link-tag-card {
    text-decoration: none;
    color: #5A5A5A;
  }

  .slider-wrapper-news-articles .timing_info {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .slider-wrapper-news-articles .article_short_info { 
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .slider-wrapper-news-articles .container {
    max-width: 960px;
    margin: 0 auto; 
  }
 
  .slider-wrapper-news-articles .card {
    background-color: #ffffff;  
    border-radius: 0;
    border: none; 
    border: 1px solid #C7C7C7;
  }

  .slider-wrapper-news-articles .card p {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .card-default-height {
    height: 430px;
  }

  .slider-wrapper-news-articles .card img {
    margin-bottom: 10px;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0; 
  }
  
  .slider-wrapper-news-articles .card h1 {
    margin-top: 10px;
    font-size: 0.9rem;
    margin-bottom: 10px;
    font-weight: 700;
    color: #000000;
    line-height: 1.5;
  }
  
@media screen and (max-width: 1199px) { 
  .slider-wrapper-news-articles .new_articles p, .slider-wrapper-news-articles .card p  {
    font-size: 1rem;
  }
  .card-default-height {
    height: 480px;
  }

}

@media screen and (max-width: 991px) { 
  .slider-wrapper-news-articles .new_articles p, .slider-wrapper-news-articles .card p {
    font-size: 1rem;
  }
}
 
@media screen and (max-width: 847px) {
  .card-default-height {
    height: 500px;
  } 
}
 

@media screen and (max-width: 802px) {
  .card-default-height {
    height: 520px;
  } 
}


@media screen and (max-width: 767px) { 
  .slider-wrapper-news-articles .new_articles p, .slider-wrapper-news-articles .card p {
    font-size: 0.9rem;
  }
  .card-default-height {
    height: 470px;
  } 
}
 
@media screen and (max-width: 640px) {  
  .card-default-height {
    height: auto;
  } 
}

@media screen and (max-width: 500px) {
  .slider-wrapper-news-articles .card h1 {
    font-size: 1.2rem;
  } 
  .slider-wrapper-news-articles .new_articles p, .slider-wrapper-news-articles .card p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 450px) {
  .slider-wrapper-news-articles .card h1 {
    font-size: 1rem;
  } 
  .slider-wrapper-news-articles .new_articles p, .slider-wrapper-news-articles .card p {
    font-size: 0.7rem;
  }
}
 
