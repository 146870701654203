.performance_group_component {
    background-color: #FFFFFF;
    user-select: none;
    cursor: pointer;
    padding-top: 1rem;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
  }

  .performance_group_component .card:hover{
    transform: scale(1.05);
    cursor: pointer;
    transition:  0.2s ease-in-out;
  }

  .performance_group_component .performance_group_sessions h1{
    color: #000000;
    font-weight: 700;
  }
  
  .performance_group_component .group_sessions_info h2 {
    margin-top: 10px;
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-weight: 700;
    color: #000000;
  }

  .performance_group_component .performance_group_sessions p {
    color: #5A5A5A;
    font-weight: 500;
    margin-left: 10rem;
    margin-right: 10rem;
    font-size: 0.9rem;
  }
 
  .performance_group_component .group_sessions_info { 
    padding-left: 1rem;
    padding-right: 1rem;
    height: 14.3rem; 
    padding-bottom: 2rem;
  }
  
  .performance_group_component .container {
    max-width: 960px;
    margin: 0 auto; 
  }

   
  .performance_group_component .card {
    background-color: #ffffff;  
    border-radius: 0px; 
    height: auto;
    transition:  0.2s ease-in-out;
    border: none; 
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
  }

  .performance_group_component .card p {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .performance_group_component .group_sessions_info .skew-btn {
    padding: 5px 35px;
    font-size: 0.8rem;
  }

  .performance_group_component .card .thumbnail-icon {
    margin-bottom: 10px;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0; 
  }
 
  .performance_group_component .card h1 {
    margin-top: 10px;
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000000;
  }
  
@media screen and (max-width: 1199px) { 
  .performance_group_component .performance_group_sessions p, .performance_group_component .card p  {
    font-size: 1rem;
  }
  .performance_group_component .performance_group_sessions p {
    margin-left:7rem;
    margin-right:7rem;
  }
  .performance_group_component .group_sessions_info {
    height: 21rem;
  }
}

@media screen and (max-width: 991px) { 
  .performance_group_component .performance_group_sessions p, .performance_group_component .card p {
    font-size: 1rem;
  }
  .performance_group_component .performance_group_sessions p {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .performance_group_component .group_sessions_info {
    height: 14rem;
  }
}

@media screen and (max-width: 767px) { 
  .performance_group_component .performance_group_sessions p, .performance_group_component .card p {
    font-size: 0.9rem;
  }
  .performance_group_component .performance_group_sessions p {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .performance_group_component .performance_group_sessions h1 {
    font-size: 1.3rem;
  }
  .performance_group_component .performance_group_sessions p {
    font-size: 0.8rem;
  } 
  .performance_group_component .group_sessions_info {
    height: 10rem;
  }
}

@media screen and (max-width: 500px) {
  .performance_group_component .card h1 {
    font-size: 1.2rem;
  } 
  .performance_group_component .performance_group_sessions p, .performance_group_component .card p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 450px) {
  .performance_group_component .card h1 {
    font-size: 1rem;
  } 
  .performance_group_component .performance_group_sessions p, .performance_group_component .card p {
    font-size: 0.7rem;
  }
}
 
