.slider-wrapper-team {
    user-select: none;
    cursor: pointer;
    margin: 0;
    padding-bottom: 7.4rem;
  }

  .slider-wrapper-team .team_info h1{
    color:#000000;
    font-weight: 700;
  }
   
  .slider-wrapper-team .team_info p {
    color: #5A5A5A;
    font-weight: 550;
    font-size: 0.9rem;
  }
  
  .slider-wrapper-team  .container {
    max-width: 960px;
    margin: 0 auto; 
    padding: 20px;
  }
 
  .slider-wrapper-team .card .review-time-lapse {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider-wrapper-team .card .review-time-lapse img{
    margin-right: 10px;
  }

  .slider-wrapper-team  .card {
    background-color: transparent; 
    padding: 20px;
    border-radius: 0;
    border: none; 
  }

  .slider-wrapper-team .card p {
    font-size: 0.8rem;
    color: #5A5A5A;
    font-weight: 500;
    margin-bottom: 0;
  }
  
  .slider-wrapper-team .card .team-member {
    width: 408.95px;
    height: auto; 
  }

  .slider-wrapper-team .card img {
    margin-bottom: 10px;
  }

  .slider-wrapper-team .swiper-pagination-bullet-active{
    background-color: #E9D06B;
  }

  .slider-wrapper-team .card h1 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #000000;
    font-weight: 700;
  }

@media screen and (max-width: 1199px) { 
    .slider-wrapper-team .team_info p, .slider-wrapper-team .card p  {
        font-size: 1rem;
    }
}

@media screen and (max-width: 991px) { 
    .slider-wrapper-team .team_info p, .slider-wrapper-team .card p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 767px) { 
    .slider-wrapper-team .team_info p, .slider-wrapper-team .card p {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 500px) {
    .slider-wrapper-team .card h1 {
        font-size: 1.2rem;
    }

    .slider-wrapper-team .team_info p, .slider-wrapper-team .card p {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 450px) {
    .slider-wrapper-team .card h1 {
        font-size: 1rem;
    }

    .slider-wrapper-team .team_info p, .slider-wrapper-team .card p {
        font-size: 0.7rem;
    }
}
 