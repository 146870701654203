.enquery_contact_component {
  padding-top: 4rem;
  background-color: #F6F5F8;
  padding-bottom: 4.5rem;
}

#contact-form {
  scroll-margin-top: 80px;  
}

.enquery_contact_component .queries_info h1{
    color: #000000;
    font-weight: 700;
}

/* .enquery_contact_component .checkbox-types label span { 
  list-style-type: none;
}
 
.enquery_contact_component .checkbox-types label span:before {
  content: "• ";
  color:#5A5A5A;

  font-weight: bold;
  display: inline-block;
  width: 1em; 
} */

.enquery_contact_component .queries_info p{
    color: #5A5A5A;
    font-weight: 500;
    font-size: 0.9rem;
}

.enquery_contact_component .form-fields .forms-input-fields label, .enquery_contact_component .checkbox-types p, .enquery_contact_component .questions, .enquery_contact_component .group{
   color: #5A5A5A;
   font-weight: 700;
}

.enquery_contact_component .form-fields .forms-input-fields.checkboxes label {
  color: #5A5A5A;
  font-weight: 600;
}

.enquery_contact_component .checkbox-types p {
  font-size: 1.1rem;
}

.enquery_contact_component .checkbox-types label {
    display: block;
    cursor: pointer;
    color: #5A5A5A;
    font-weight: 500 !important;
    font-family: 'Montserrat', sans-serif !important;
    user-select: none;
    margin-bottom: 10px;
  }

  .enquery_contact_component .checkbox-types input[type="checkbox"] {  
    width: 15px;
    height: 15px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    outline: none;
    margin-right: 10px;
    cursor: pointer;
  }

  .enquery_contact_component .group_checkboxes label {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    margin-bottom: 10px;
  }

  .enquery_contact_component .group_checkboxes input[type="checkbox"] { 
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
  }
   
  .enquery_contact_component .form-fields .forms-input-fields input[type="text"],
  .enquery_contact_component .form-fields .forms-input-fields  input[type="email"],
  .enquery_contact_component .form-fields textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: 1px solid #9e9e9e;
    border-radius: 0px;
    resize: none;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .enquery_contact_component .form-fields .forms-input-fields input[type="text"],
  input[type="email"],
  textarea:focus {
    outline: none;
  }

  .enquery_contact_component .form-fields .questions textarea {
    min-height: 230px;
    max-height: 260px;
  }

  .enquery_contact_component .form-fields .forms-input-fields input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  textarea::placeholder {
    color: #A7A7A7;
    font-weight: 500;
  }

  .enquery_contact_component .form-fields .forms-input-fields input[type="checkbox"] {
    margin-right: 10px;
  }

  .enquery_contact_component .bring_friends_section h2 {
    color: #1D1F2C;
    font-weight: 700;
  }

  .enquery_contact_component .bring_friends_section p{
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  @media screen and (max-width: 1199px) { 
    .enquery_contact_component .queries_info p {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 991px) { 
    .enquery_contact_component .queries_info p{
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 767px) { 
    .enquery_contact_component .queries_info p{
      font-size: 0.9rem;
    }
  }
  
  @media screen and (max-width: 500px) { 
    .enquery_contact_component .queries_info p{
      font-size: 0.8rem;
    }
  }
  
  @media screen and (max-width: 450px) {

    .enquery_contact_component .queries_info p{
      font-size: 0.7rem;
    }
  }