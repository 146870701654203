.founder_about_component {
    padding-top: 5rem;
    padding-bottom: 1rem;
    overflow-x: hidden;
}

.founder_about_component .founder-icon {
   width: auto;
   height: 360px;
   padding: 10px; 
}

.founder_about_component .donna-content h1{
   color: #000000;
   font-weight: 700;
}

.donna-sign-logo {
    width: auto;
    height: 100px;
}

.founder_about_component .donna-content p{
    color: #5A5A5A;
    font-weight: 500;
    font-size: 0.9rem;
}

.founder_about_component .donna-content p span {
    font-weight: 600;
}

@media screen and (max-width: 1199px) { 
    p {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 991px) { 
    p {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 767px) { 
    p {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 500px) { 
    p {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 450px) { 
    p {
        font-size: 0.7rem;
    }
}
 