.quest_sp_banner_component {
    height: 800px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;  
}

.quest_sp_banner_component::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    z-index: 1;
}

.wrapper-div { z-index: 99 } 

.quest_sp_banner_regular_component { 
    width: 100%; 
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;  
    color: #fff;
}

.quest_sp_banner_component .skew-btn:hover {
    background-color: #000000; 
    border: 2px solid #000000;
    color: #E9D06B; 
    transition: ease-in-out 0.2s;  
}

.quest_sp_banner_regular_component h1 {
    font-weight: 700;
    font-size: 4rem;
}

.quest_sp_banner_regular_component span {
    color: #ffffff;
    font-weight: 700;
}

.quest_sp_banner_component h1{
    font-weight: 700;
    color: #fff;
    font-size: 3.9rem;
    text-transform: uppercase;
}

.quest_sp_banner_component p {
    color: #fff;
}

.quest_sp_banner_component .skew-btn {
    background-color: transparent  ;
    border: 2px solid #E9D06B;
    color: #E9D06B;
    transition: ease-in-out 0.3s;
    font-weight: 700;
}

.quest_sp_banner_component .skew-btn .nav-link {
    color: #E9D06B;
}

@media (max-width: 991px) {
    .quest_sp_banner_component h1 {
        font-size: 3.7rem;
    }

    .quest_sp_banner_component .skew-btn {
        padding: 7px 40px;
        font-size: 17px;
    } 
}

@media (max-width: 768px) {
    .quest_sp_banner_component h1 {
        font-size: 3.1rem;
    }

    .quest_sp_banner_component .skew-btn {
        padding: 7px 40px;
        font-size: 17px;
    }
}

@media (max-width: 600px) {
    .quest_sp_banner_component h1 {
        font-size: 2.8rem;
    }

    .quest_sp_banner_component .skew-btn {
        padding: 7px 38px;
        font-size: 17px;
    }
}

@media (max-width: 500px) {
    .quest_sp_banner_regular_component h1 {
        font-size: 2.7rem;
    }

    .quest_sp_banner_component h1 {
        font-size: 2.5rem;
    }

    .quest_sp_banner_component .skew-btn {
        padding: 7px 38px;
        font-size: 15px;
    }
}

@media (max-width: 400px) {
    .quest_sp_banner_regular_component h1 {
        font-size: 2.3rem;
    }

    .quest_sp_banner_component h1 {
        font-size: 2.2rem;
    }

    .quest_sp_banner_component .skew-btn {
        padding: 7px 38px;
        font-size: 15px;
    }

    .quest_sp_banner_component .skew-btn {
        padding: 4px 32px;
        font-size: 15px;
    }
}

@media (max-width: 360px) { 
    .quest_sp_banner_regular_component h1 {
        font-size: 1.9rem;
    }

    .quest_sp_banner_component h1 {
        font-size: 1.8rem;
    }

    .quest_sp_banner_component .skew-btn {
        padding: 4px 30px;
        font-size: 15px;
    }
}