.coaching_associations_logos { 
    position: relative; 
    width: 70%;
    padding-bottom: 2.1rem;
    padding-top: 2.5rem;
    transform: translateY(-30%);
    margin: 0 auto;
    border: 1px solid white;
    border-radius: 25px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 8px 52px -6px rgba(0, 0, 0, 0.2);
}

.coaching_associations_logos h2 {
    color: #000000;
    font-weight: 700;
    text-align: center;
    font-size: 2.1rem;
    text-transform: uppercase;
}

.coaching_associations_logos .container {
    max-width: 1200px;  
    margin: 0 auto;
}

@media (max-width: 600px) {
    .coaching_associations_logos h2 {
        font-size: 1.8rem;
    }
}

@media (max-width: 500px) {
    .coaching_associations_logos h2 {
        font-size: 1.5rem; 
    }
}


@media (max-width: 300px) {
    .coaching_associations_logos h2 {
        font-size: 1.3rem;
    }
}