.mlb_athlete_component {
    user-select: none;
    padding-top: 2rem;
    margin-top: 2rem;
    padding-bottom: 2rem;
}

.mlb_athlete_component .mlb_info h1 {
    color: #E9D06B;
    font-weight: 700;
    font-size: 2rem;
    text-transform: uppercase;
}

.mlb_athlete_component .mlb_info p {
    color: #5A5A5A;
    font-size: 0.9rem;
}
 
.mlb_athlete_component .player  {
    border-radius: 0px;
    overflow: hidden;
  }

  .mlb_athlete_component a {
    text-decoration: none;
  }

  .mlb_athlete_component a:hover {
    text-decoration: none; 
  }

  .card.player {
    transition: all 0.2s ease-in-out; 
    border-radius: 3px;
  }

  .card.player:hover {
    border-radius: 3px;
    transform: scale(1.05);  
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);  
  }
  
  .mlb_athlete_component .player img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .mlb_athlete_component .player .card-text {
    padding: 10px;
    text-align: center;
    background-color: #5A5A5A;
    text-transform: uppercase;
    color: #E9D06B; 
  }

  .mlb_action .skew-btn {
    font-size: 17px;
  }
  
  .mlb_athlete_component .player .card-text h2 {
    margin-top: 0;
    font-style: italic;
    font-size: 1.3rem; 
    font-weight: 700;
  }
   
@media screen and (max-width: 768px) {
    .mlb_athlete_component .mlb_info h1 {
        font-size: 1.7rem; 
    }

    .mlb_athlete_component .mlb_info p {
        font-size: 0.8rem;
    }

    .mlb_action .skew-btn {
        font-size: 15px;
    } 
}

@media screen and (max-width: 433px) {
    .mlb_athlete_component .mlb_info h1 {
        font-size: 1.5rem; 
    }

    .mlb_athlete_component .mlb_info p {
        font-size: 0.8rem;
    }
}
 
@media screen and (max-width: 385px) {
    .mlb_athlete_component .mlb_info h1 {
        font-size: 1.3rem; 
    }

    .mlb_athlete_component .mlb_info p {
        font-size: 0.8rem;
    }
}
 