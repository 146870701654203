.img_by_cn_section p, .list-items li  {
    font-family: 'Montserrat', sans-serif;
}

.img_by_cn_section {  
    margin-top: 7rem;
    margin-bottom: 6rem; 
    user-select: none; 
    overflow-x:hidden
}  

.list-items-logo {
    margin-top: -1rem !important;
    max-width: 600px;
}

.img_by_cn_section .bullet-point {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #5A5A5A;
    margin-right: 2px;
}

.parent_img_cn .container-fluid {
    max-width: 1939px;
    width: 100%;
}

.header-container .swoosh-img { 
   height: 60px;
   width: 500px;
   margin-right: auto;
   margin-top: -35px;
   margin-left: -54px;
}

.parent_img_cn .img_by_cn_section .li-item {
    margin-bottom: 2px;
    font-size: 0.9rem;
    color: #5A5A5A !important;
    font-weight: 500 !important;
}

.parent_img_cn .img_by_cn_section .row-list-item-heading {
    font-weight: 600;
    margin-bottom: 2px;
    color: #000000;
    font-size: 0.9rem;
}

.img_by_cn_section .content_img {
    width: auto;
    min-height: auto;
    max-height: 613.71px;
}

.img_by_cn_section h1 {
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
}
 
.img_by_cn_section p {
    color: #5A5A5A;
    font-weight: 500; 
}

.img_by_cn_section .highlighted-para {
    color: #5A5A5A;
    text-transform: uppercase;
    font-weight: 700; 
}

.img_by_cn_section .list-items {
    list-style-type: none;
    padding: 0; 
    margin: 0;
}

.list-item-str {
    font-style: italic;
    font-weight: 800 !important;
}

.img_by_cn_section .list-items li {
   font-weight: 500;
   color: #5A5A5A;
   font-size: 16.5px;
}

.img_by_cn_section .list-items .list-item-str::before {
    display: none !important;
}
 
.img_by_cn_section .list-items li::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 15px;
    background-color: #5A5A5A;
    border-radius: 50%;
}

.img_by_cn_section  .list-items {
    /* columns: 2;
    column-gap: auto; */
  }
  
  .img_by_cn_section  .list-items li {
    display: block; 
  }


@media screen and (max-width: 1490px) {
    .img_by_cn_section h1 { 
        font-size: 1.6rem;
    }   

    .img_by_cn_section p { 
        font-size: 1rem;
    }   

    .img_by_cn_section .list-items li {
        font-size: 19px;
    }
    
    .extra-logo {
        width: 90px;
        margin-top: 1.8rem;
        height: 90px;
    }

    .img_by_cn_section .skew-btn {
        padding: 5px 36px;
    }
}

@media screen and (max-width: 1299px) {
    .img_by_cn_section h1 { 
        font-size: 1.4rem;
    }   

    .img_by_cn_section p { 
        font-size: 1rem;
    }   

    .img_by_cn_section .list-items li {
        font-size: 15px;
    }

    .extra-logo {
        width: 90px;
        margin-top: 1.8rem;
        height: 90px;
    }

    .img_by_cn_section .skew-btn {
        padding: 4px 30px;
    }
}

@media screen and (max-width: 1100px) {
    .img_by_cn_section h1 { 
        font-size: 1.2rem;
    }   

    .img_by_cn_section p { 
        font-size: 1rem;
    }   

    .extra-logo {
        width: 90px;
        margin-top: 1.8rem;
        height: 90px;
    }

    .img_by_cn_section .list-items li {
        font-size: 14px;
    }

    .img_by_cn_section .skew-btn {
        padding: 7px 23px;
    }
}

@media screen and (max-width: 991px) {
    .img_by_cn_section h1 { 
        font-size: 2.5rem;
    }   

    .img_by_cn_section p { 
        font-size: 1rem;
    }   

    .img_by_cn_section .list-items li {
        font-size: 17px;
    } 

    .img_by_cn_section .skew-btn {
        padding: 7px 40px;
        font-size: 17px;
    }
}

@media screen and (max-width: 768px) {
    .img_by_cn_section h1 { 
        font-size: 2rem;
    }   

    .img_by_cn_section p { 
        font-size: 1rem;
    }   

    .img_by_cn_section .list-items li {
        font-size: 16px;
    }

    .img_by_cn_section .skew-btn {
        padding: 7px 40px;
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    .img_by_cn_section h1 { 
        font-size: 1.7rem;
    }    
}

@media screen and (max-width: 450px) { 
    .img_by_cn_section h1 { 
        font-size: 1.5rem;
    }    
}

@media screen and (max-width: 380px) { 
    .img_by_cn_section p {
        font-size: 0.8rem;
    }
 }
  